import {
  HSFacility,
  HSPatient, HSPatientMovement,
} from 'server-openapi';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import {Entry, IStorage} from '../core/storage/Contract';
import { SyncStreamAPI } from './api';
import {IFacilityGroupSyncService} from './SyncCenter';
import { SyncUtils } from './utils/SyncUtils';
import { Logger } from '../core/logger/logger';
import { DateUtils } from '../core/utils/dateUtils';
import { startOfDay, subDays } from 'date-fns';
import {MemoryCache} from "../core/storage/MemoryCache";
import {parseInt} from "lodash-es";

export interface UpdatePatientMovementInfoOp {
  type: 'update-patient-movement-info';
  request: {
    patientMovement: HSPatientMovement;
    facilityGroupId: number;
  };
}


const logger = new Logger('SyncPatientMovements');

export class SyncPatientMovements implements IFacilityGroupSyncService {
  get name(): string {
    return 'SyncPatientMovements';
  }

  private async isStale(p: HSPatient): Promise<boolean> {
    return !!((!p.active && p.lastUpdated && p.lastUpdated < DateUtils.fromDate(subDays(startOfDay(new Date()), 14))));
  }

  private async isFacilityGroup(p: HSPatientMovement, facilityGroupId: string): Promise<boolean> {
    const facility = await this.facilitiesStore.get(p.movedFrom!.facilityId!.toString());
    return facility?.facilityGroupId?.toString() == facilityGroupId;
  }

  // Initialise the patient store to an empty store.  It will get written properly when the load method is called.
  constructor(
    private api: SyncStreamAPI,
    private storage: MemoryCache<HSPatientMovement>,
    private facilitiesStore: IStorage<HSFacility>,
    private patientsStore: IStorage<HSPatient>,
    private latestChangeNumbers: IStorage<number | undefined>,
    private queue: PersistentQueue<UpdatePatientMovementInfoOp>,
    private epochStore: MemoryCache<string>,
  ) {}

  patientMovements: HSPatientMovement[] = [];
  async load(facilityGroupId: string): Promise<void> {
    await this.storage.reset(async (p) => {
          return await this.isFacilityGroup(p, facilityGroupId);
    });
  }

  //eslint-disable-next-line sonarjs/cognitive-complexity
  async syncDown(facilityGroupId?: string) {
    const facilitiesToSync = await SyncUtils.getFacilitiesForGroup(facilityGroupId, this.facilitiesStore);
    const changeNumber = await SyncUtils.getChangeNumberForFacilities(
        facilitiesToSync.map((x) => x.hsId!),
        this.latestChangeNumbers,
    );

    // fetch patient movements for all facilities within facility group
    const patientMovementData = await this.syncFacilityDown(
      facilityGroupId!, // non-null assertion on facility group id
      facilitiesToSync.map((facility) => facility.hsId!),
      changeNumber);

    for (const patientMovement of patientMovementData) {
      const patient = await this.patientsStore.get(patientMovement.patientId!.toString()) as HSPatient;

      //
      // Is the version of the patient from the in-memory store older than what is on the patientmovement record we have fetched form the server?
      // If so, then that would mean they have potentially been moved to a new facility (associated with the patientmovement) so that what we
      // currently think the facility they are in now here (based on in-memory store) is now outdated, so we should update their facility to
      // the new one from the patientmovement. This will ensure the patient no longer incorrectly shows in the current facility on the screen
      // when they shouldn't
      //
      if (patient !== null && (!patientMovement.patientVersion || patient.version! < patientMovement.patientVersion!)) {

        // Does the in-memory store of facilities already contain an entry for the facility they've moved to? If not, then
        // it's a facility not used in medisphere so patient would need to be removed.
        const targetFacility = await this.facilitiesStore.get(patientMovement.movedTo!.facilityId!.toString()) as HSFacility;

        // target facility is the one we are syncing. Move the patient there.
        if (!!targetFacility) {
          const updatedPatient = { ...patient, facility: patientMovement?.movedTo?.facilityId };
          await this.patientsStore.set(patient.hsId!.toString(), updatedPatient);
        } else {
          await this.patientsStore.delete(patientMovement.patientId!.toString())
        }
      }
    }


    await this.storage.setMany(
      patientMovementData
        .map((patientMovement) => ({
          key: this.storage.get_key!(patientMovement),
          value: patientMovement,
        }))
    );

    await SyncUtils.setChangeNumberForFacilities(
        facilitiesToSync.map((facility) => facility.hsId!),
        this.latestChangeNumbers,
        patientMovementData
    );
  }

  private async syncFacilityDown(
    facilityGroupId: string,
    facilityIds: number[],
    changeNumber: number,
  ): Promise<HSPatientMovement[]> {
    const pageSize = 200;
    //changeNumber = isFinite(changeNumber) ? changeNumber : 0;
    const patientMovements = await this.api.patientMovements.patientMovementsListPatientMovements(
      parseInt(facilityGroupId),
      changeNumber,
      pageSize,
    );

    this.patientMovements = patientMovements.data;

    if (patientMovements.data.length === pageSize) {
      return [
        ...patientMovements.data,
        ...(await this.syncFacilityDown(facilityGroupId, facilityIds, SyncUtils.getLatestChangeNumber(patientMovements.data)!)),
      ];
    }
    return patientMovements.data;
  }

  enqueue = {
    updatePatientMovementInfo: async (req: UpdatePatientMovementInfoOp) => {
      // add updated patient movement object to local store
      const updatedPatientMovement = {
        key: req.request.patientMovement.hsId!.toString(),
        value: req.request.patientMovement,
      };

      await this.storage.set(updatedPatientMovement.key, updatedPatientMovement.value);
      // add the request to the queue
      await this.queue.unshift({ ...req });
      return updatedPatientMovement;
    },
    patientMovements: this.patientMovements
  };

  async clear() {
    await this.storage.clear();
    await this.latestChangeNumbers.clear();
    await this.queue.clear();
  }

  async hasQueuedData() {
    return (await this.queue.length()) > 0;
  }
  isAllowed(canUserAccessMedication: boolean): boolean {
    // Only if you can view a round.
    return canUserAccessMedication;
  }
  async archive(): Promise<string[]> {
    const keysToDelete: string[] = [];
    for (let [k, v] of (await this.storage.all()).entries()) {
      if (await this.isStale(v)) {
        keysToDelete.push(k)
      }
    }
    await this.storage.deleteMany(keysToDelete);
    return keysToDelete;
  }

  setEncryptionVersion(version: number): void {
    this.storage.compressOnSave = (version > 1);
  }
  async rewrite(): Promise<void> {
    const entries: Entry<HSPatient>[] = [...(await this.storage.all())].map((keyValueArray) => {
      return {
        key: keyValueArray[0],
        value: keyValueArray[1]
      };
    });
    return await this.storage.setMany(entries);
  }
}
