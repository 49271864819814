/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import _ from 'lodash';
import {
  CreateUserMessageType,
  CreateUserStatus,
  LoginType,
  RoleDto,
  UserInvitationDto,
  UserRoleAssignmentDto,
} from 'server-openapi';
import styled from 'styled-components';
import {NavigationBar} from '../../../components/NavigationBar/NavigationBar';
import {apis} from '../../../core/mrs/apis';
import {Button, Variation} from '../../../kit/Button';
import {StyledTextInput, TextInput} from '../../../kit/Forms/TextInput';
import {Grid} from '../../../kit/Grid';
import {useAsync} from '../../../kit/hooks/UseAsync';
import {IconBack} from '../../../kit/Icons/Back';
import {useForm} from 'react-hook-form';
import {Form} from '../../../kit/Forms/Form';
import {toasts} from '../../../kit/Toasts/Toaster';
import {format} from 'date-fns';
import {Layout} from '../../../kit/Layout';
import {RolesDetailsSection} from './components/RolesDetailsSection';
import {Intent} from '../../../kit/Theme/Theme';
import {DateUtils} from '../../../core/utils/dateUtils';
import {RequirePermission} from '../../../components/RequirePermission/RequirePermission';
import {ValidationMessages} from '../../../core/utils/validationMessages';
import {Select, SelectOption} from "../../../kit/Forms/Select";
import {useGroupPermissions} from "../../../core/authz/PermissionsProvider";
import {useSyncCenter} from '../../../syncstream/SyncCenterProvider';
import {useStore} from '../../../core/storage/hooks/UseStore';
import {Tooltip} from "react-tooltip";
import {IoInformationCircle} from "react-icons/io5";

interface IParams {
  facilityGroupId: string;
  userId: string;
}

export interface RoleAccessDto {
  identifier: number | undefined;
  role: RoleDto;
  startDate: Date;
  expiryDate: Date | undefined;
  softDelete: boolean;
  isNew: boolean;
}

export type UserDetailsFormValues = {
  firstName: string;
  familyName: string;
  email: string;
  loginType: LoginType;
  designation: string;
  roleAccess: RoleAccessDto[];
};

export function UserDetailsPage() {
  const groupPermissions = useGroupPermissions();
  return (
    <Grid cols={1} gap={1}>
      <RequirePermission hasPermission={groupPermissions.canViewFacilityGroupConfigurationIncludingUserManagement}>
        <UserDetailContent/>
      </RequirePermission>
    </Grid>
  );
}

function UserDetailContent() {
  const facilityGroupId = parseInt(useParams<IParams>().facilityGroupId);
  const userId = parseInt(useParams<IParams>().userId);
  const isNewUser = useParams<IParams>().userId === 'new';
  const history = useHistory();
  const previousPagePath = `/admin/facility-group/${facilityGroupId}/settings/users`;
  const [user, setUser] = useState<UserInvitationDto>();

  useAsync(async () => {
    if (!isNewUser) {
      const response = (await apis.users.userGetUserInvitationById(userId)).data;
      setUser(response);
    }
  });

  const [roles, setRoles] = useState<RoleDto[]>([]);
  useAsync(async () => {
    setRoles((await apis.roles.roleGetAll(facilityGroupId)).data);
  });

  function goToPreviousPage() {
    history.push(previousPagePath);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async function onSubmit(data: UserDetailsFormValues) {
    data.roleAccess.forEach((role) => {
      // Refer to line 92 in UserRolesDetails.tsx
      if (role.expiryDate?.toString() === 'Invalid Date') {
        role.expiryDate = undefined;
      }
    });

    const {firstName, familyName, email, designation, roleAccess, loginType} = data;

    const userRoleAssignments =
      roleAccess?.filter(r => r.role?.urn && (!r.softDelete || !r.isNew)).map((r): UserRoleAssignmentDto => {
        return {
          id: r.identifier,
          roleUrn: r.role.urn!,
          startAt: DateUtils.fromDate(r.startDate),
          endAt: r.expiryDate ? DateUtils.fromDate(r.expiryDate) : undefined,
          softDelete: r.softDelete
        };
      }) ?? [];
    if (!isNewUser) {
      await apis.users.userUpdateUserInvitation({
        facilityGroupId: facilityGroupId,
        id: userId,
        loginType: loginType,
        designation,
        userRoleAssignments,
      }).then(() => {
        toasts.success(`${user?.firstName} ${user?.familyName} has been ${isNewUser ? 'added' : 'updated'} successfully`);
        goToPreviousPage();
      }).catch(err => {
        toasts.error(`Error updating user: ${err}`);
      });
    }
    else {
      const userToInvite = {
        email,
        firstName,
        familyName,
        loginType,
        designation,
        userRoleAssignments,
      };
      await apis.users.userInviteUser({
        facilityGroupId: facilityGroupId,
        userDetails: userToInvite
      }).then((resp) => {
        if (resp.data.createUserStatus === CreateUserStatus.Created) {
          goToPreviousPage();
        }
        for (const msg of (resp.data.messages ?? [])) {
          if (msg.message) {
            let intent: Intent = Intent.Success;
            let timeout = 5;
            if (msg.messageType === CreateUserMessageType.Warning) {
              intent = Intent.Warning;
              timeout = 10;
            } else if (msg.messageType === CreateUserMessageType.Error) {
              intent = Intent.Danger;
              timeout = 10;
            }
            toasts.toast(msg.message, {
              intent: intent,
              timeoutSeconds: timeout
            });
          }
        }
      }).catch(err => {
        toasts.error(`Error updating user: ${err}`);
      });
    }

  }

  const activeUserRoleAssignments = user?.userRoleAssignments?.filter((ura) => !ura.softDelete);

  const form = useForm<UserDetailsFormValues>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    shouldUnregister: false // entering value into Designation field removes newly added role field (created by clicking on +Add Additional Role button). This prevents the issue.
  });

  useEffect(() => {
    form.setValue('firstName', user?.firstName ?? '');
    form.setValue('familyName', user?.familyName ?? '');
    form.setValue('email', user?.email ?? '');
    form.setValue('designation', user?.designation ?? '');
    form.setValue(
      'roleAccess',
      activeUserRoleAssignments?.map((roleAssignment) => {
        const role = roles.find((r) => r.urn === roleAssignment.roleUrn);

        const startAt = roleAssignment?.startAt;
        const endAt = roleAssignment?.endAt;

        return {
          identifier: roleAssignment.id,
          role: role,
          startDate: startAt ? DateUtils.toDate(startAt) : undefined,
          expiryDate: endAt ? DateUtils.toDate(endAt) : undefined,
          softDelete: roleAssignment?.softDelete ?? false,
          isNew: false
        };
      })
    );
  }, [user, roles]);


  return (
    <>
      <NavigationSection user={user} isNewUser={isNewUser} previousPagePath={previousPagePath}/>
      <Layout gap={1} padding="20px 50px">
        <Form form={form} onSubmit={onSubmit}>
          <UserDetailsSection user={user} isNewUser={isNewUser} setUser={setUser} facilityGroupId={facilityGroupId}/>
          {user && roles && (
            <RolesDetailsSection user={user} roles={roles} form={form} facilityGroupId={facilityGroupId}/>
          )}

          <PageNavigationButtonContainer cols={2} gap={1}>
            <Button style={{width: '250px'}} variation={Variation.outline} intent={Intent.Secondary}
                    onClick={goToPreviousPage} fullWidth>
              CANCEL
            </Button>
            <Button style={{width: '250px'}} type="submit" fullWidth>
              SAVE
            </Button>
          </PageNavigationButtonContainer>
        </Form>
      </Layout>
    </>
  );
}

function NavigationSection(props: { user?: UserInvitationDto; isNewUser: boolean; previousPagePath: string }) {
  const fullName = (user?: UserInvitationDto) => {
    if (!!user)
      return user.firstName + ' ' + user.familyName;
    else {
      return "";
    }
  };


  return (
    <NavigationBar
      nodes={() => ([
        <Grid colsTemplate="35px 1fr">
          <Link to={props.previousPagePath}>
            <IconBack/>
          </Link>
          <h1>{props.isNewUser ? 'New User' : fullName(props.user)}</h1>
        </Grid>
      ])}
    />
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function UserDetailsSection(props: {
  user?: UserInvitationDto;
  isNewUser: boolean;
  setUser: (user: UserInvitationDto) => void;
  facilityGroupId: number;
}) {
  const {user, isNewUser, setUser} = props;
  const services = useSyncCenter();
  const facilityGroupConfigurationStore = useStore(services.facilityGroupConfigurations.store).store;
  const loginTypes = facilityGroupConfigurationStore.get(props.facilityGroupId.toString())?.loginTypes;
  const loginTypeOptions = loginTypes?.map((t) => ({
    label: _.startCase(_.toLower(t)),
    value: LoginType[_.startCase(_.toLower(t)) as keyof typeof LoginType]
  } as SelectOption<LoginType>));

  if (!loginTypes) throw new Error('No Login Types found in Facility Configurations for this facilityGroup. Looks like the Facility Configurations was not synced.');

  if (!user && !isNewUser) {
    return null;
  }


  return (
    <>
      <HeaderText>User Details</HeaderText>
      <SectionInputsContainer>
        <Grid colsTemplate="1fr 1fr">
          {isNewUser && (
            <>
              <Layout gap={0.5}>
                <div>First Name <span style={{color: 'red'}}>*</span></div>
                <TextInput
                  name="firstName"
                  value={user?.firstName ?? ''}
                  onChange={(_, value) => setUser({...user, firstName: value})}
                  autoComplete="off"
                  fullWidth
                  required
                />
              </Layout>
              <Layout gap={0.5}>
                <div>Family Name <span style={{color: 'red'}}>*</span></div>
                <TextInput
                  name="familyName"
                  value={user?.familyName ?? ''}
                  onChange={(_, value) => setUser({...user, familyName: value})}
                  fullWidth
                  required
                  autoComplete="off"
                />
              </Layout>
              <Layout gap={0.5}>
                <div>Email <span style={{color: 'red'}}>*</span></div>
                <TextInput
                  name="email"
                  value={user?.email ?? ''}
                  onChange={(_, value) => setUser({...user, email: value})}
                  fullWidth
                  required
                  autoComplete="off"
                  rules={{
                    validate: (email: string) =>
                      ValidationMessages.email.regex!.test(email) || ValidationMessages.email.message,
                  }}
                />
              </Layout>
            </>
          )}
          {!isNewUser && (
            <>
              <Layout gap={0.5}>
                <div>Name</div>
                <StyledTextInput value={`${user?.firstName} ${user?.familyName}`} fullWidth disabled/>
              </Layout>
              <Layout gap={0.5}>
                <div>Email</div>
                <StyledTextInput value={user?.email ?? ''} fullWidth disabled/>
              </Layout>
              <Layout gap={0.5}>
                <div>Date Added</div>
                <StyledTextInput
                  value={user?.createdAt ? format(DateUtils.toDate(user.createdAt), 'd MMMM yyyy') : ''}
                  fullWidth
                  disabled
                />
              </Layout>
            </>
          )}
          <Layout gap={0.5}>
            <Tooltip id="login-type-tooltip"/>
            <div style={{display: 'flex'}}>Login Type <IoInformationCircle data-tooltip-id="login-type-tooltip"
                                                                           data-tooltip-html="<div>Local: Uses a personally managed email account.</div><div>Sso (Single Sign On): Uses a corporate managed email account.</div>"
                                                                           size='20px' style={{marginLeft: '5px'}}/>
            </div>
            <Select
              name="loginType"
              options={loginTypeOptions!}
              value={user?.loginType ?? LoginType.Sso}
              onChange={(_, value) => {
                setUser({...user, loginType: value});
              }}
              fullWidth
              required
            />
          </Layout>
          <Layout gap={0.5}>
            <div>Designation <span style={{color: 'red'}}>*</span></div>
            <TextInput
              name="designation"
              autoComplete="off"
              value={user?.designation ?? ''}
              onChange={(_, value) => setUser({...user, designation: value})}
              fullWidth
              required
            />
          </Layout>
          {!isNewUser && (
            <Layout gap={0.5}>
              <div>Subject ID</div>
              <TextInput
                name="subject-id"
                value={user?.subjectId ?? ''}
                fullWidth
                disabled
                autoComplete="off"
              />
            </Layout>
          )}
        </Grid>
      </SectionInputsContainer>
    </>
  );
}

const HeaderText = styled.h2`
    margin-left: 20px;
`;

const SectionInputsContainer = styled(Layout)`
    background: ${(p) => p.theme.backgrounds.lighter.bg};
    padding: 20px;
    margin-bottom: 2em
`;

const PageNavigationButtonContainer = styled(Grid)`
    background-color: #FFFFFF;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    right: 0;
    padding: 25px 50px;
`;
