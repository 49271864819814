/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  HSDoseRound,
  HSDrug,
  HSDrugForm,
  HSFacility,
  HSFacilityGroup,
  HSPackedPatientDay,
  HSPackedPatientPrnMedication,
  HSPatient,
  ListSecondCheckSettingDto,
  HSTestResultType,
  PinUserDto,
  HSPatchObservation,
  NimAvailableDrugDto,
  HSUser,
  HSCorporate,
  FacilityGroupConfigurationDto,
  DrugCategoryMapping,
  HSTestResult,
  HSSyringeDriverActivity, HSPatientMovement,
} from 'server-openapi';
import { createServiceProvider } from '../context/AppServiceProvider';
import { PersistentQueue } from '../core/queue/PersistentQueue';
import { IndexedDBStorage } from '../core/storage/IndexedDBStorage';
import { MemoryCache } from '../core/storage/MemoryCache';
import { SyncStreamAPI } from './api';
import { SyncDrugs } from './SyncDrugs';
import { SyncCenter} from './SyncCenter';
import { SyncFacilities } from './SyncFacilities';
import { SyncFacilityGroups } from './SyncFacilityGroups';
import { SyncPackedPatientDays } from './SyncPackedPatientDays';
import { SyncPatients } from './SyncPatients';
import { SyncRounds, RoundOp } from './SyncRounds';
import { SyncPackedPatientPrnMedication } from './SyncPackedPatientPrnMedication';
import { SyncDrugForms } from './SyncDrugForms';
import { SyncSecondCheckSettings } from './SyncSecondCheckSettings';
import { MedisphereTestResult, SyncTestResults, TestResultOp } from './SyncTestResults';
import { SyncTestResultTypes } from './SyncTestResultTypes';
import { SyncPinUsers } from './SyncPinUsers';
import { SyncOrders } from './SyncOrders';
import { SyncUsers } from './SyncUsers';
import { IOrderService, Order } from '../common/Order';
import { PatchObservationOp, SyncPatchObservations } from './SyncPatchObservations';
import { SyncNimAvailableDrugs } from './SyncNimAvailableDrugs';
import { SyncCorporates } from './SyncCorporates';
import { SyncFacilityGroupConfigurations } from './SyncFacilityGroupConfigurations';
import {
  MedisphereSyringeDriverActivity,
  SyncSyringeDriverActivities,
  SyringeDriverActivityOp,
} from './SyncSyringeDriverActivity';
import {
  AppState, AppStateContext,
  encryptionVersion, isLoggingOff,
  setEncryptionVersion,
  useAppState,
} from '../context/AppStateProvider';
import { SyncDrugCategories } from './SyncDrugCategories';
import { ServerErrorHandlerDialog } from '../components/ServerErrorHandler/ServerErrorHandlerDialog';
import { useHealthCheck } from '../core/healthcheck/HealthCheckProvider';
import { LoadingScreen } from '../components/LoadingScreen/LoadingScreen';
import { DateUtils } from '../core/utils/dateUtils';
import {addDays, startOfDay} from 'date-fns';
import {useGroupPermissions} from "../core/authz/PermissionsProvider";
import {Logger} from "../core/logger/logger";
import {StoreConfigurationDto} from "../core/storage/StoreConfigurationDto";
import {SyncPatientMovements} from "./SyncPatientsMovements";
const logger = new Logger('SyncCenterProvider');

export interface AppServices {
  syncCenter: SyncCenter;
  epochStore: MemoryCache<string>;
  patientMovements: {
    store: MemoryCache<HSPatientMovement>,
    service: SyncPatientMovements
  };
  patients: {
    store: MemoryCache<HSPatient>;
    service: SyncPatients;
  };
  facilities: {
    store: MemoryCache<HSFacility>;
    service: SyncFacilities;
  };
  facilityGroups: {
    store: MemoryCache<HSFacilityGroup>;
    service: SyncFacilityGroups;
  };
  packedPatientDays: {
    store: MemoryCache<HSPackedPatientDay>;
    service: SyncPackedPatientDays;
  };
  packedPatientPrnMedications: {
    store: MemoryCache<HSPackedPatientPrnMedication>;
    service: SyncPackedPatientPrnMedication;
  };
  drugs: {
    store: MemoryCache<HSDrug>;
    service: SyncDrugs;
  };
  drugForms: {
    store: MemoryCache<HSDrugForm>;
    service: SyncDrugForms;
  };
  drugCategories: {
    store: MemoryCache<DrugCategoryMapping>;
    service: SyncDrugCategories;
  };
  rounds: {
    store: MemoryCache<HSDoseRound>;
    service: SyncRounds;
    queue: PersistentQueue<RoundOp>;
  };
  secondCheckSettings: {
    store: MemoryCache<ListSecondCheckSettingDto>;
    service: SyncSecondCheckSettings;
  };
  testResults: {
    store: MemoryCache<MedisphereTestResult>;
    service: SyncTestResults;
    queue: PersistentQueue<TestResultOp>;
  };
  testResultTypes: {
    store: MemoryCache<HSTestResultType>;
    service: SyncTestResultTypes;
  };
  pinUsers: {
    store: MemoryCache<PinUserDto>;
    service: SyncPinUsers;
  };
  orders: {
    store: MemoryCache<Order>;
    service: IOrderService;
  };
  patchObservations: {
    store: MemoryCache<HSPatchObservation>;
    service: SyncPatchObservations;
    queue: PersistentQueue<PatchObservationOp>;
  };
  nimAvailableDrugs: {
    store: MemoryCache<NimAvailableDrugDto>;
    service: SyncNimAvailableDrugs;
  };
  users: {
    store: MemoryCache<HSUser>;
    service: SyncUsers;
  };
  corporates: {
    store: MemoryCache<HSCorporate>;
    service: SyncCorporates;
  };
  facilityGroupConfigurations: {
    store: MemoryCache<FacilityGroupConfigurationDto>;
    service: SyncFacilityGroupConfigurations;
  };
  syringeDriverActivity: {
    store: MemoryCache<MedisphereSyringeDriverActivity>;
    service: SyncSyringeDriverActivities;
    queue: PersistentQueue<SyringeDriverActivityOp>;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function plainStore<T = any>(name: string, idFn?: (obj: T) => string) {
  const disk = await IndexedDBStorage.open(name, idFn);
  return await MemoryCache.open<T>(name, disk, idFn);
}
async function loadLaterStore<T = any>(name: string, idFn?: (obj: T) => string) {
  const disk = await IndexedDBStorage.open(name, idFn);
  return new MemoryCache<T>(name, disk, new Map(), idFn);
}

async function plainQueue(name: string, appState? : AppState) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return await PersistentQueue.open<any>(await plainStore(name));
}

async function initialiseEpoch(epoch: MemoryCache<string>, reset: boolean) {
  if (reset) {
    await epoch.clear();
  }

  if (!(await epoch.get(""))) {
    // The epoch store is empty.  Seed it.
    // Initialise the epoch store to
    await epoch.set("", DateUtils.fromDate(addDays(startOfDay(new Date()), -14)));
  }
}
// eslint-disable-next-line max-lines-per-function,sonarjs/cognitive-complexity
async function bootstrap(
  api: SyncStreamAPI,
  syncMessage: string,
  setSyncMessage: Dispatch<SetStateAction<string>>,
  progress: number,
  setProgress: Dispatch<SetStateAction<number>>,
  appState: AppStateContext<AppState>,
  enableInitialSync?: boolean,
  onInitialSyncComplete?: () => void,
  disableSyncing?: boolean,
  resetStores?: boolean,
): Promise<AppServices> {
  setProgress(1);
  setSyncMessage(".");
  const epochStore = await plainStore<string>('epoch');

  await initialiseEpoch(epochStore, !!resetStores);

  const storeConfigurationStore = await plainStore<StoreConfigurationDto>('store-configuration', (x) => x.facilityGroupId);
  const facilityGroupConfigurationsStore = await plainStore<FacilityGroupConfigurationDto>('facility-group-configurations', (x) => x.facilityGroupHsId?.toString() ?? '');

  const facilitiesStore = await plainStore<HSFacility>('facilities', (x) => x.hsId?.toString() ?? '');
  const facilitiesService = new SyncFacilities(api, facilitiesStore, await plainQueue('facilities-operations'));

  const facilityGroupsStore = await plainStore<HSFacilityGroup>('facility-groups', (x) => x.hsId?.toString() ?? '');
  const facilityGroupsService = new SyncFacilityGroups(
    api,
    facilityGroupsStore,
    await plainQueue('facility-groups-operations'),
  );

  const facilityGroupConfigurationsService = new SyncFacilityGroupConfigurations(
      api,
      facilityGroupConfigurationsStore,
      facilityGroupsStore,
  );

  const patientsStore = await loadLaterStore<HSPatient>('patients', (x) => x.hsId?.toString() ?? '');
  const patientsChangeNumbers = await plainStore('patients-latest-change-numbers');
  const patientsService = new SyncPatients(
    api,
    patientsStore,
    facilitiesStore,
    patientsChangeNumbers,
    await plainQueue('patients-operations'),
    epochStore
  );

  const patientMovementsStore = await loadLaterStore<HSPatientMovement>('patient-movements', (x) => x.hsId?.toString() ?? '');
  const patientMovementsChangeNumbers = await plainStore('patient-movements-latest-change-numbers');
  const patientMovementsService = new SyncPatientMovements(api, patientMovementsStore, facilitiesStore, patientsStore, patientMovementsChangeNumbers, await plainQueue('patient-movements'), epochStore);

  setProgress(2);
  setSyncMessage("..");
  const packedPatientDaysStore = await loadLaterStore<HSPackedPatientDay>('packed-patient-days', (x) => x.hsId?.toString() ?? '');

  setProgress(2);
  setSyncMessage("...");
  const packedPatientDaysChangeNumbers = await plainStore('packed-patient-days-latest-change-numbers');
  const packedPatientDaysService = new SyncPackedPatientDays(
    api,
    packedPatientDaysStore,
    facilitiesStore,
    packedPatientDaysChangeNumbers,
    await plainQueue('packed-patient-days-operations'),
  );

  setProgress(3);
  setSyncMessage("....");
  // TODO: Expose data so that prn meds for inactive patients can be archived.
  const packedPatientPrnMedicationsStore = await loadLaterStore('packed-patient-prn-medications', (x) => x.hsId?.toString() ?? '');
  const packedPatientPrnMedicationsChangeNumbers = await plainStore('packed-patient-prn-medications-latest-change-numbers');
  const packedPatientPrnMedicationsService = new SyncPackedPatientPrnMedication(
    api,
    packedPatientPrnMedicationsStore,
    facilitiesStore,
    packedPatientPrnMedicationsChangeNumbers,
    await plainQueue('packed-patient-prn-medications-operations'),
  );

  const drugsStore = await plainStore<HSDrug>('drugs', (x) => x.hsId?.toString() ?? '');
  // , (d) => {
  //   // Remove drugs that have been inactive for over 2 days.
  //   return !!(
  //     !d.active &&
  //     d.lastModifiedAt &&
  //     d.lastModifiedAt < DateUtils.fromDate(subDays(startOfDay(new Date()), 14))
  //   );
  // });
  const drugsChangeNumbers = await plainStore('drugs-latest-change-numbers');
  const drugsService = new SyncDrugs(api, drugsStore, drugsChangeNumbers, await plainQueue('drugs-operations'), epochStore);

  const drugFormsStore = await plainStore<HSDrugForm>('drug-forms', (x) => x.hsId?.toString() ?? '');
  const drugFormsChangeNumbers = await plainStore('drug-forms-latest-change-numbers');
  const drugFormsService = new SyncDrugForms(
    api,
    drugFormsStore,
    drugFormsChangeNumbers,
    await plainQueue('drug-forms-operations'),
    epochStore
  );

  setProgress(4);
  setSyncMessage(".....");
  const drugCategoriesStore = await plainStore<DrugCategoryMapping>('drug-categories', (x) => x.drug_id?.toString() ?? '');
  const drugCategoriesChangeNumbers = await plainStore('drug-categories-latest-change-numbers');
  const drugCategoriesService = new SyncDrugCategories(api, drugCategoriesStore, drugCategoriesChangeNumbers);

  const roundsStore = await plainStore<HSDoseRound>('rounds', (x) => x.clinicalSystemId ?? '');
  const roundsChangeNumbers = await plainStore('rounds-latest-change-numbers');
  const roundsQueue = await plainQueue('rounds-operations');
  const roundsService = new SyncRounds(api, roundsStore, facilitiesStore, roundsChangeNumbers, roundsQueue);

  const secondCheckSettingsStore = await plainStore<ListSecondCheckSettingDto>('secondCheckSettings');
  const secondCheckSettingsService = new SyncSecondCheckSettings(
    api,
    secondCheckSettingsStore,
    facilityGroupsStore,
    await plainQueue('secondCheckSettings-operations'),
  );

  const testResultsStore = await loadLaterStore<HSTestResult>('test-results', (x) => x.hsId?.toString() ?? '');
  const testResultsChangeNumbers = await plainStore('test-results-change-numbers', (x) => x.clinicalSystemId ?? x.hsId?.toString() ?? '');
  const testResultsQueue = await plainQueue('test-results-operations');
  const testResultsService = new SyncTestResults(
    api,
    testResultsStore,
    facilitiesStore,
    roundsStore,
    testResultsChangeNumbers,
    testResultsQueue,
  );

  const testResultTypesStore = await plainStore<HSTestResultType>('test-result-types', (x) => x.hsId?.toString() ?? '');
  const testResultTypesService = new SyncTestResultTypes(api, testResultTypesStore);

  const pinUsersStore = await plainStore<PinUserDto>('pin-users', (x) => x.subjectId?.toString() ?? '');
  const pinUsersService = new SyncPinUsers(
    api,
    pinUsersStore,
    facilitiesStore,
    await plainQueue('pinUsers-operations'),
  );

  setProgress(5);
  setSyncMessage("......");
  const nimAvailableDrugsStore = await plainStore<NimAvailableDrugDto>('nim-available-drugs', (x) => x.id?.toString() ?? '');
  const nimAvailableDrugsService = new SyncNimAvailableDrugs(
    api,
    nimAvailableDrugsStore,
    await plainQueue('nimAvailableDrugs-operations'),
  );

  // TODO:  Have a closer look at the order keys.
  const ordersStore = await loadLaterStore<Order>('orders');

  const ordersQueue = await plainQueue('orders-operations');
  const ordersChangeNumbers = await plainStore('orders-change-numbers', (x) => x.hsId?.toString() ?? '');
  const ordersService = new SyncOrders(api, ordersStore, ordersQueue, facilitiesStore, ordersChangeNumbers);

  setProgress(6);
  setSyncMessage(".......");

  const patchObservationsStore = await loadLaterStore<HSPatchObservation>('patch-observations', (x) => x.clinicalSystemId ?? x.hsId?.toString() ?? '');
  const patchObservationsChangeNumbers = await plainStore('patch-observations-change-numbers', (x) => x.hsId?.toString() ?? '');
  const patchObservationsQueue = await plainQueue('patch-observations-operations');
  const patchObservationsService = new SyncPatchObservations(
    api,
    patchObservationsStore,
    facilitiesStore,
    patchObservationsChangeNumbers,
    patchObservationsQueue,
  );

  setProgress(7);
  setSyncMessage("........");
  const usersStore = await loadLaterStore<HSUser>('users', (x) => x.hsId?.toString() ?? '');
  setProgress(8);
  setSyncMessage(".........");
  const usersChangeNumbers = await plainStore('users-latest-change-numbers');
  setProgress(9);
  setSyncMessage("..........");
  const usersService = new SyncUsers(api, usersStore, facilitiesStore, usersChangeNumbers, epochStore);

  setProgress(10);
  setSyncMessage("...........");
  const corporatesStore = await plainStore<HSCorporate>('corporates', (x) => x.hsId?.toString() ?? '');
  const corporatesService = new SyncCorporates(api, corporatesStore);


  setProgress(11);
  setSyncMessage("............");
  const syringeDriverActivityStore = await loadLaterStore<HSSyringeDriverActivity>('syringe-driver-activity', (x) => x.clinicalSystemId ?? x.hsId?.toString() ?? '');
  const syringeDriverActivityChangeNumbers = await plainStore('syringe-driver-activity-latest-change-numbers');
  const syringeDriverActivityQueue = await plainQueue('syringe-driver-activity-operations');
  const syringeDriverActivityService = new SyncSyringeDriverActivities(
    api,
    syringeDriverActivityStore,
    facilitiesStore,
    syringeDriverActivityChangeNumbers,
    roundsStore,
    syringeDriverActivityQueue,
  );

  setProgress(12);
  setSyncMessage("..............");
  const syncCenter = new SyncCenter(
      epochStore,
      storeConfigurationStore,
      facilitiesService,
      facilityGroupsService,
      corporatesService,
      roundsService,
      drugsService,
      drugFormsService,
      drugCategoriesService,
      testResultTypesService,
      secondCheckSettingsService,
      pinUsersService,
      nimAvailableDrugsService,
      facilityGroupConfigurationsService,
      patientsService,
      patientMovementsService,
      packedPatientDaysService,
      packedPatientPrnMedicationsService,
      ordersService,
      patchObservationsService,
      usersService,
      testResultsService,
      syringeDriverActivityService,
      disableSyncing,
      );

  if (enableInitialSync) {
    // Clear out the selected facility group id.
    // This will force the user to select the facility group they are interested in.
    await syncCenter.clearStores(!!resetStores);
    await syncCenter.performInitialSync();
    onInitialSyncComplete && onInitialSyncComplete();
  }
  setSyncMessage("Computing round schedule...");
  setProgress(0);

  logger.info("Completed Bootstrap");
  return {
    syncCenter: syncCenter,
    epochStore: epochStore,
    patients: {
      store: patientsStore,
      service: patientsService,
    },
    patientMovements: {
      store: patientMovementsStore,
      service: patientMovementsService
    },
    facilities: {
      store: facilitiesStore,
      service: facilitiesService,
    },
    facilityGroups: {
      store: facilityGroupsStore,
      service: facilityGroupsService,
    },
    packedPatientDays: {
      store: packedPatientDaysStore,
      service: packedPatientDaysService,
    },
    packedPatientPrnMedications: {
      store: packedPatientPrnMedicationsStore,
      service: packedPatientPrnMedicationsService,
    },
    drugs: {
      store: drugsStore,
      service: drugsService,
    },
    drugForms: {
      store: drugFormsStore,
      service: drugFormsService,
    },
    drugCategories: {
      store: drugCategoriesStore,
      service: drugCategoriesService,
    },
    rounds: {
      store: roundsStore,
      service: roundsService,
      queue: roundsQueue,
    },
    secondCheckSettings: {
      store: secondCheckSettingsStore,
      service: secondCheckSettingsService,
    },
    testResults: {
      store: testResultsStore,
      service: testResultsService,
      queue: testResultsQueue,
    },
    testResultTypes: {
      store: testResultTypesStore,
      service: testResultTypesService,
    },
    pinUsers: {
      store: pinUsersStore,
      service: pinUsersService,
    },
    orders: {
      store: ordersStore,
      service: ordersService,
    },
    patchObservations: {
      store: patchObservationsStore,
      service: patchObservationsService,
      queue: patchObservationsQueue,
    },
    nimAvailableDrugs: {
      store: nimAvailableDrugsStore,
      service: nimAvailableDrugsService,
    },
    users: {
      store: usersStore,
      service: usersService,
    },
    corporates: {
      store: corporatesStore,
      service: corporatesService,
    },
    facilityGroupConfigurations: {
      store: facilityGroupConfigurationsStore,
      service: facilityGroupConfigurationsService,
    },
    syringeDriverActivity: {
      store: syringeDriverActivityStore,
      service: syringeDriverActivityService,
      queue: syringeDriverActivityQueue,
    },
  };
}

const [ServiceProvider, useServices] = createServiceProvider<AppServices>();

export enum InitialSyncErrorReason {
  Offline,
  Other,
}

interface Props {
  enableInitialSync?: boolean;
  resetStores?: boolean;
  onInitialSyncComplete?: () => void;
  children?: React.ReactNode;
  disableSyncing?: boolean;
  useAsReactComponent?: boolean;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function SyncCenterProvider(props: Props) {
  //console.count('SyncCenterProvider');
  const logger = new Logger('SyncCenterProvider');
  const [loadingMessage, setLoadingMessage] = useState("Starting Application...");
  const [syncMessage, setSyncMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [appServices, setAppServices] = useState<AppServices | undefined>();

  //common error flag for network disconnection or other reasons
  const [initialSyncErrorDialogOpen, setInitialSyncErrorDialogOpen] = useState<InitialSyncErrorReason | undefined>(
    undefined,
  );

  //check if the sync error is due to server disconnection or other reason
  function checkInitialSyncError(error: any) {
    logger.error("Error syncing", error);
    if (isServerHealthy) {
      setInitialSyncErrorDialogOpen(InitialSyncErrorReason.Other);
    }
    else {
      setInitialSyncErrorDialogOpen(InitialSyncErrorReason.Offline);
    }
  }

  const appState = useAppState();
  const isServerHealthy = useHealthCheck().isHealthy;
  const [canUserAccessMedication, setCanUserAccessMedication] = useState<boolean | undefined >(undefined);
  const groupPermissions = useGroupPermissions();
  useEffect (() => {
    setCanUserAccessMedication(groupPermissions.canListPatients);
  }, [groupPermissions]);


  useEffect(() => {
    const interval = setInterval(async () => {
      var message: string = "Initializing Application...";
      var encryptionMessage: string = "";

      if (!appServices) message = `Loading Application...`;
      if (appState.state.isApplicationLoading === true) message = `Initializing Application...`;

      if (encryptionVersion >= 2) encryptionMessage = `and Encrypting`;
      if (!appState.state.isFacilityGroupLoaded) message = `Loading Facility Data`;
      if (appState.state.isFacilityGroupInitialSyncComplete?.complete === false && appState.state.isApplicationLoading === true) message = `Syncing Facility Data`;
      if (appState.state.isFacilityGroupInitialSyncComplete === undefined) message = `Initializing ${encryptionMessage} Facility Data`;
      if (appState.state.isFacilityGroupInitialSyncComplete?.complete === true && appState.state.isApplicationLoading === false && appServices) message = `Loading Facility Data`;
      if (appState.state.isFacilityGroupInitialSyncComplete?.complete === false) message = `Syncing Facility Data...`;
      if (isLoggingOff) {
        message = `Logging off...`;
      }

      setLoadingMessage(message + syncMessage);
    }, 500);

    return () => clearInterval(interval);
  },[syncMessage, loadingMessage, progress, encryptionVersion, appState.state.isApplicationLoading, appState.state.isFacilityGroupInitialSyncComplete?.complete, appState.state.isFacilityGroupLoaded]);


  const init = async () => {
    setSyncMessage("Initializing... ");
    const api = new SyncStreamAPI();
    await bootstrap(api, syncMessage, setSyncMessage, progress, setProgress, appState, props.enableInitialSync, props.onInitialSyncComplete, props.disableSyncing, props.resetStores)
      .then((op) => {
        setSyncMessage("...");
        appState.set({...appState.state, isApplicationLoading: false});
        setAppServices(op);
        setSyncMessage(".....");
      })
      .catch(checkInitialSyncError);
  };


  useEffect(() => {
    init();
    return () => {
      if (appServices) {
        appServices.syncCenter.stop();
      }
    };
  }, []);

  useEffect(() => {
    appServices?.syncCenter.stop();
    if (!appState.state.selectedFacilityGroupId || !appServices || !appState.state.isFacilityGroupInitialSyncComplete) {
      return;
    }

    if (!appState.state.isFacilityGroupInitialSyncComplete.complete && canUserAccessMedication !== undefined) {
      appServices.facilityGroupConfigurations.store.get(appState.state.selectedFacilityGroupId?.toString() ?? '').then((c) => {
            //console.info("c?.encryptionVersion ?? 0" + c?.encryptionVersion ?? 0);
            setEncryptionVersion(c?.encryptionVersion ?? 0);
          }
      );

      appServices.syncCenter
          .performInitialFacilitySync(appState.state.selectedFacilityGroupId, canUserAccessMedication)
          .then((result) => {
            if (result && !!appState.state.isFacilityGroupInitialSyncComplete) {
              appState.set({
                isFacilityGroupInitialSyncComplete: {
                  id: appState.state.selectedFacilityGroupId ?? '',
                  complete: true,
                },
                isFacilityGroupLoaded: true
              });
              appServices.syncCenter.start(canUserAccessMedication, appState.state.selectedFacilityGroupId);
            }
          })
          .catch(checkInitialSyncError);
    }
    else if (canUserAccessMedication !== undefined && !appState.state.isFacilityGroupLoaded) {
      // Here we have refreshed the page.  We only need to reload it from disk.
      // i.e. we don't need to perform full sync, or archive data.
      appServices.syncCenter.performFacilityGroupLoad(appState.state.selectedFacilityGroupId).then((result) => {
        if (result) {
          appState.set({
            isFacilityGroupLoaded: true
          });
          appServices.syncCenter.start(canUserAccessMedication, appState.state.selectedFacilityGroupId);
        }
      });
    }
    else if (canUserAccessMedication !== undefined) {
      appServices.syncCenter.start(canUserAccessMedication, appState.state.selectedFacilityGroupId);
    }
  }, [appServices, appState.state.isFacilityGroupInitialSyncComplete, canUserAccessMedication, appState.state.isFacilityGroupLoaded]);

  // Grab the services that need to be downloaded for each new facility group.
  useEffect(() => {
    if (appState.state.isNewFacilityGroup && appServices && appState.state.selectedFacilityGroupId) {
      appServices.syncCenter.performSingleFacilityGroupLoad(appState.state.selectedFacilityGroupId).then((result) => {
        if (result) {
          appState.set({
            isNewFacilityGroup: false
          });
        }
      });
    }
  }, [appServices, appState.state.isNewFacilityGroup, appState.state.selectedFacilityGroupId]);

  useEffect(() => {
    if (appState.state.selectedFacilityGroupId &&
      appState.state.isFacilityGroupInitialSyncComplete?.id !== appState.state.selectedFacilityGroupId) {
      appState.set({
        isFacilityGroupInitialSyncComplete: {id: appState.state.selectedFacilityGroupId, complete: false},
      });
    }
  }, [appState.state.selectedFacilityGroupId, appState.state.isFacilityGroupInitialSyncComplete]);

  if (initialSyncErrorDialogOpen) {
    return <ServerErrorHandlerDialog errorReason={initialSyncErrorDialogOpen} isServerHealthy={isServerHealthy} />;
  }

  if (
    !appServices ||
    ((!appState.state.isFacilityGroupInitialSyncComplete?.complete || !appState.state.isFacilityGroupLoaded) &&
     appState.state.selectedFacilityGroupId &&
     !props.disableSyncing)
  ) {

    return <LoadingScreen message={loadingMessage}/>;
  }

  return <ServiceProvider value={appServices}>{props.children}</ServiceProvider>;
}

export function useSyncCenter() {
  return useServices();
}
