import {
  Configuration,
  PatientsApi,
  PermissionsApi,
  FacilitiesApi,
  FacilityGroupsApi,
  EmailApi,
  DrugsApi,
  PackedPatientDayApi,
  RoundsApi,
  UserApi,
  RoleApi,
  ImageApi,
  PackedPatientPrnMedicationApi,
  AdministeredDoseApi,
  AdministeredDrugApi,
  ReportingApi,
  DrugFormsApi,
  NimAvailableDrugApi,
  NimConsumerApi,
  SecondCheckSettingApi,
  AdministeredDrugCommentApi,
  AdministeredDrugOutcomeApi,
  AdministeredAdHocDrugOutcomeApi,
  AdministeredAdHocDrugCommentApi,
  TestResultApi,
  RoundSegmentApi,
  OrdersApi,
  OrdersCommentApi,
  PatchObservationsApi,
  AdministeredAdHocDrugApi,
  PatientProgressNoteApi,
  CorporateApi,
  SyringeDriverActivitiesApi,
  SyringeDriverActivityCommentsApi,
  MedicationChartApi,
  WarningDetailsApi,
  PatientMedicationApi,
  PatchManagementApi,
  DrugCategoriesApi,
  SessionApi, GlobalSettingsApi, PatientMovementsApi
} from 'server-openapi';
import { appconfig } from '../../appconfig';
import { getUser, usermanager } from '../authn/Client';
import { setupCache } from 'axios-cache-adapter';
import axios, { AxiosRequestConfig } from "axios";
import localforage from 'localforage';
import { EventEmitter } from 'events';
import { setupSessionEvents } from "./session";
import { randomHex } from "../utils/random";
import { logApiError } from "../logger/sentry";

const forageStore = localforage.createInstance({
  driver: [localforage.LOCALSTORAGE],
  name: 'http-cache',
});

const config = new Configuration({
  basePath: appconfig.API_URL,
  apiKey: () => {
    return `Bearer ${getUser()?.access_token}`;
  },
});

const traceHeadersInterceptor = [
  // Although sentry supports distributed tracing, at the moment what we need
  // is a new trace for each API request. Sentry's default is to start a new
  // trace with each page load, or with the react router extension with each
  // route. While that is good for performance tracing, we are currently using
  // distributed tracing for diagnosing error conditions, and it helps to have
  // a separate trace id for each API call.
  (config: AxiosRequestConfig) => {
    const traceId = randomHex(32);
    const spanId = randomHex(16);
    const traceParent = `00-${traceId}-${spanId}-00`;
    // The w3c trace context standard says the tracestate header must be present,
    // so just put the span id in it to be compliant.
    const traceState = `msapp=${spanId}`;
    config.headers["traceparent"] = traceParent;
    config.headers["tracestate"] = traceState;
    return config;
  },
  (error: any) => error
];

export const AxiosEventEmitter = new EventEmitter();
const errorInterceptor = [
  (response: any) => response,
  (error: any) => {
    logApiError(error);
    AxiosEventEmitter.emit('error', error);
    throw error;
  },
];

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(...traceHeadersInterceptor);
axiosInstance.interceptors.response.use(...errorInterceptor);

const axiosInstanceWithCache = axios.create({
  adapter: setupCache({
    store: forageStore,
    maxAge: Number.MAX_SAFE_INTEGER,
    exclude: {
      query: false,
    },
  }).adapter,
});
axiosInstanceWithCache.interceptors.response.use(...errorInterceptor);

export const apis = {
  email: new EmailApi(config, undefined, axiosInstance),
  facilityGroups: new FacilityGroupsApi(config, undefined, axiosInstance),
  facilities: new FacilitiesApi(config, undefined, axiosInstance),
  corporates: new CorporateApi(config, undefined, axiosInstance),
  users: new UserApi(config, undefined, axiosInstance),
  roles: new RoleApi(config, undefined, axiosInstance),
  patients: new PatientsApi(config, undefined, axiosInstance),
  patientMovements: new PatientMovementsApi(config, undefined, axiosInstance),
  permissions: new PermissionsApi(config, undefined, axiosInstance),
  drugs: new DrugsApi(config, undefined, axiosInstance),
  drugForms: new DrugFormsApi(config, undefined, axiosInstance),
  drugCategories: new DrugCategoriesApi(config, undefined, axiosInstance),
  packedPatientDays: new PackedPatientDayApi(config, undefined, axiosInstance),
  packedPatientPrnMedications: new PackedPatientPrnMedicationApi(config, undefined, axiosInstance),
  rounds: new RoundsApi(config, undefined, axiosInstance),
  images: new ImageApi(config, undefined, axiosInstanceWithCache),
  administeredDoses: new AdministeredDoseApi(config, undefined, axiosInstance),
  administeredDrugs: new AdministeredDrugApi(config, undefined, axiosInstance),
  nimAvailableDrugs: new NimAvailableDrugApi(config, undefined, axiosInstance),
  nimConsumers: new NimConsumerApi(config, undefined, axiosInstance),
  secondCheckSettings: new SecondCheckSettingApi(config, undefined, axiosInstance),
  reporting: new ReportingApi(config, undefined, axiosInstance),
  administeredDrugComments: new AdministeredDrugCommentApi(config, undefined, axiosInstance),
  administeredDrugOutcomes: new AdministeredDrugOutcomeApi(config, undefined, axiosInstance),
  administeredAdHocDrugComments: new AdministeredAdHocDrugCommentApi(config, undefined, axiosInstance),
  administeredAdHocDrugOutcomes: new AdministeredAdHocDrugOutcomeApi(config, undefined, axiosInstance),
  testResults: new TestResultApi(config, undefined, axiosInstance),
  roundSegments: new RoundSegmentApi(config, undefined, axiosInstance),
  orders: new OrdersApi(config, undefined, axiosInstance),
  orderComments: new OrdersCommentApi(config, undefined, axiosInstance),
  patchObservations: new PatchObservationsApi(config, undefined, axiosInstance),
  administeredAdHocDrugs: new AdministeredAdHocDrugApi(config, undefined, axiosInstance),
  patientProgressNotes: new PatientProgressNoteApi(config, undefined, axiosInstance),
  syringeDriverActivities: new SyringeDriverActivitiesApi(config, undefined, axiosInstance),
  syringeDriverActivityComments: new SyringeDriverActivityCommentsApi(config, undefined, axiosInstance),
  medicationCharts: new MedicationChartApi(config, undefined, axiosInstance),
  WarningDetailsApi: new WarningDetailsApi(config, undefined, axiosInstance),
  GlobalSettingsApi: new GlobalSettingsApi(config, undefined, axiosInstance),
  PatientMedicationApi: new PatientMedicationApi(config, undefined, axiosInstance),
  PatchManagementApi: new PatchManagementApi(config, undefined, axiosInstance),
  SessionApi: new SessionApi(config, undefined, axiosInstance)
};

setupSessionEvents(usermanager, apis.SessionApi);

export interface UploadAttachmentInput {
  patientId: number;
  facilityGroupId: number;
  file: File;
}

export interface UploadAttachmentOutput {
  attachmentId: string;
}

export async function uploadAttachment(input: UploadAttachmentInput) {
  const formData = new FormData();
  formData.append("patient_id", input.patientId.toString());
  formData.append("facility_group_id", input.facilityGroupId.toString());
  formData.append("file_name", input.file.name);
  formData.append("attachment", input.file);
  const url = `${config.basePath}/api/upload_attachment`;
  const response = await axiosInstance.post<UploadAttachmentOutput>(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Accept": "application/json",
      "Authorization": `Bearer ${getUser()?.access_token}`
    }
  });

  return response.data;
}

export async function fetchAttachment(url: string) {
  const response = await axiosInstance.get<Blob>(url,
      {
        responseType: 'blob',
        headers: {
          "Accept": "application/pdf",
          "Authorization": `Bearer ${getUser()?.access_token}`
        }
      });
  return response.data;
}
