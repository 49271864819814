import React, {useEffect} from 'react';
import {RoleDto, UserInvitationDto} from 'server-openapi';
import {Button} from '../../../../kit/Button';
import {SelectOption} from '../../../../kit/Forms/Select';
import {useFieldArray} from 'react-hook-form';
import {UserRolesDetails} from './UserRolesDetails';
import {UserDetailsFormValues, RoleAccessDto} from '../UserDetailsPage';
import styled from 'styled-components';
import {Layout} from '../../../../kit/Layout';
import {UseFormMethods} from 'react-hook-form';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";

export function RolesDetailsSection(props: {
  user: UserInvitationDto;
  roles: RoleDto[];
  form: UseFormMethods<UserDetailsFormValues>;
  facilityGroupId: number;
}) {
  const {form} = props;


  return (
    <>
      <HeaderText>Roles Details</HeaderText>
      <RoleFormOptionsDropdown roles={props.roles} form={form} facilityGroupId={props.facilityGroupId}/>
    </>
  );
}

interface IRoleFormOptionsDropdown {
  roles: RoleDto[];
  form: UseFormMethods<UserDetailsFormValues>;
  facilityGroupId: number;
}

function RoleFormOptionsDropdown(props: IRoleFormOptionsDropdown) {
    const {roles, form} = props;
    const groupPermissions = useGroupPermissions();
    const corporateOptionsVisible = groupPermissions.canManageCorporateUser;

    const allOptionsVisible = groupPermissions.canAssignRolesWithUserManagementPermission;
    const nonManagementOptionsVisible = groupPermissions.canAssignRolesExcludingUserManagementPermission;


    const basicUserManagementRolesOptionsVisible = groupPermissions.canAssignBasicUserManagementRoles;
    const registeredNurseRolesOptionsVisible = groupPermissions.canAssignRegisteredNurseRoles;
    const receptionistRolesOptionsVisible = groupPermissions.canAssignReceptionistRoles;

    const canAssignRoles = allOptionsVisible || nonManagementOptionsVisible || basicUserManagementRolesOptionsVisible || registeredNurseRolesOptionsVisible;
    const corporateLabels = ['Corporate Reporting'];
    const nonManagementLabels = ['Carer', 'Agency Nurse', 'Agency EN', 'Enrolled Nurse', 'Second Checker', 'Med Comp Carer', 'Med Assist', 'Disability Support Worker'];
    const basicUserManagementRoleLabels = ['Basic User Management'];
    const registeredNurseRoleLabels = ['Registered Nurse'];
    const receptionistRoleLabels = ['Reporting', 'Read Only'];

    const roleCanBeEdited = (r: RoleDto): boolean => {
        const roleName = r.name ?? '';
        return !!(corporateOptionsVisible ||
        (allOptionsVisible && !corporateLabels.includes(roleName)) ||
        (nonManagementOptionsVisible && nonManagementLabels.includes(roleName)) ||
        (basicUserManagementRolesOptionsVisible && basicUserManagementRoleLabels.includes(roleName)) ||
        (registeredNurseRolesOptionsVisible && registeredNurseRoleLabels.includes(roleName)) ||
        (receptionistRolesOptionsVisible && receptionistRoleLabels.includes(roleName)));

    }

    const {fields, append} = useFieldArray<RoleAccessDto>({
        name: 'roleAccess',
        control: form.control,
    });

    useEffect(() => {
        if (fields.length === 0) {
            append({startDate: new Date(), isNew: true, softDelete: false});
        }
        form.control.shouldUnregister = true; // entering value into Designation field removes newly added role field (created by clicking on +Add Additional Role button). This prevents the issue.
    }, []);

  return (
    <SectionInputsContainer gap={1} padding="20px">
      {fields.map((field, index) => {
        if (field.role) {
          const isFieldDisabled =
            !corporateOptionsVisible &&
            !!field.role &&
            field.role?.name !== undefined &&
            field.role?.name !== null && !roleCanBeEdited(field.role);

          const roleOptions = roles
            .sort((a, b) => a.name!.localeCompare(b.name!))
            .filter((r) => roleCanBeEdited(r) || r.name === field.role?.name)
            .map((r) => ({label: r.name, value: r} as SelectOption<RoleDto>));
          return (
            <UserRolesDetails
              key={field.id}
              field={field}
              index={index}
              form={form}
              roleOptions={roleOptions}
              disabled={isFieldDisabled}
              // roleDisabled={!field?.isNew ?? false}
            />
          );
        }
      })
      }
      <Button
        onClick={() => {
          append({
            startDate: new Date(),
            isNew: true,
            softDelete: false,
            expiryDate: undefined,
            role: {name: null, urn: null}
          });
        }}
        disabled={!canAssignRoles}
      >
        + Add {fields.length > 0 ? 'additional ' : ''} role
      </Button>
    </SectionInputsContainer>
  );
}

const HeaderText = styled.h2`
    margin-left: 20px;
`;

const SectionInputsContainer = styled(Layout)`
    background: ${(p) => p.theme.backgrounds.lighter.bg};
    margin-bottom: 10em;
`;
