import itiriri from 'itiriri';
import React, { useState } from 'react';
import { LoginType, RoleDto, UserOnboardingReq} from 'server-openapi';
import { CsvParser } from '../../../../../components/CsvParser';
import { ValidationMessages } from '../../../../../core/utils/validationMessages';
import { Grid } from '../../../../../kit/Grid';
import { Intent } from '../../../../../kit/Theme/Theme';
import { DateUtils } from '../../../../../core/utils/dateUtils';
import { useSyncCenter } from '../../../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../../../core/storage/hooks/UseStore';
import _ from 'lodash';
import { SelectOption } from '../../../../../kit/Forms/Select';

interface IProps {
  facilityGroupId: number;
  roles: RoleDto[];
  usersSelected: (usersToImport: UserOnboardingReq[]) => void;
}

export function ImportUsersDialogContent(props: IProps) {
  const [isLoading, setIsLoading] = useState(false);

    const services = useSyncCenter();
    const facilityGroupConfigurationStore = useStore(services.facilityGroupConfigurations.store).store;
    const loginTypes = facilityGroupConfigurationStore.get(props.facilityGroupId.toString())?.loginTypes;

    return (
    <>
      <Grid gap={1}>
        <h2>Import users from a CSV file</h2>
        <CsvParser
          intent={Intent.Secondary}
          fullWidth
          columnRules={[
            { name: 'Name', match: /^[a-zA-Z0-9 ,.'-]+$/i },
            { name: 'Email', match: ValidationMessages.email.regex },
            { name: 'LoginType', match: loginTypes! },
            { name: 'Roles', match: props.roles.map((r) => r.name!), isValueCommaSeparated: true },
          ]}
          onChange={async (data) => {
            setIsLoading(true);

            props.usersSelected(importUsers(data, props.facilityGroupId, props.roles));
          }}
          disabled={ isLoading }
        />
      </Grid>
    </>
  );
}

function importUsers(
  usersToImport: Record<string, string>[],
  facilityGroupId: number,
  roles: RoleDto[],
): UserOnboardingReq[] {
  return itiriri(usersToImport)
    .map((u) => {
      const nameTokens = u.Name.trim().split(' ');
      const firstName = nameTokens[0];
      const familyName = nameTokens[nameTokens.length - 1];

      return {
        email: u.Email,
        firstName,
        familyName,
        loginType: LoginType[_.startCase(_.toLower(u.LoginType)) as keyof typeof LoginType],
        userRoleAssignments: roles
          .filter((r) =>
            u.Roles.split(',')
              .map((val) => val.trim())
              .includes(r.name!),
          )
          .map((r) => {
            return { roleUrn: r.urn!, startAt: DateUtils.fromDate(new Date()), softDelete: false };
          }),
      };
    })
    .toArray();
}
