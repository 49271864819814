/* eslint-disable max-lines-per-function */
import React, {useCallback, useEffect, useState} from 'react';
import {
  AdHocReasonCode,
  HSAdministeredAdHocDrug,
  HSAdministeredDrug,
  HSDoseRound,
  HSPackedMedication,
  HSPackedPrnMedication,
  HSPatchObservation,
  HSTestResult,
  MedicationType,
  NimAvailableDrugDto,
  PackType,
  ReasonCode,
  SyringeDriverActivityKind
} from 'server-openapi';

import styled from 'styled-components';
import {Order} from '../../../../common/Order';
import {useCurrentUser} from '../../../../core/authn/UserProvider';
import {DateUtils, Interval} from '../../../../core/utils/dateUtils';
import {Button} from '../../../../kit/Button';
import {Grid} from '../../../../kit/Grid';
import {
  MedicationListProps,
  SecondCheckableMedication,
} from '../MedicationListsTabbedRouter/MedicationListsTabbedRouter';
import {Layout} from '../../../../kit/Layout';
import {Text} from '../../../../kit/Text';
import {DrugUtils} from '../../../../syncstream/utils/DrugUtils';
import {useApiUtils} from '../../../../syncstream/utils/hooks/useApiUtils';
import {
  getPatchStatusText,
  PatchInfo,
  PatchStatus,
} from '../MedicationListsTabbedRouter/TabLists/PatchesMedicationList';
import {MedicationGroup, ResidentDetailsUtils} from '../../../../syncstream/utils/ResidentDetailsUtils';
import {User} from 'oidc-client';
import {
  MedicationAdministerProps,
  MedicineAdministerDialog,
  runMedicationStatusUpdateApiProcedure,
} from '../MedicineAdministerDialog';
import {PatchAdministerDialog, PatchAdministerProps} from '../patches/PatchAdministerDialog';
import {SecondCheckDialog} from '../SecondCheckDialog';
import {MedicationInfoSection, StatusLabelInfo} from './MedicationInfoSection';
import {SecondCheckDetailsBar} from './SecondCheckDetailsBar';
import {PatchUtils} from '../patches/PatchUtils';
import {toasts} from '../../../../kit/Toasts/Toaster';
import {DrugOutcome, DrugOutcomeDialog} from './DrugOutcomeDialog';
import {useSyncCenter} from '../../../../syncstream/SyncCenterProvider';
import {useStore} from '../../../../core/storage/hooks/UseStore';
import {SyringeDriverAdministerDialog} from '../syringeDrivers/SyringeDriverAdministrationForm';
import {isSyringeDriverActivity, ScheduledActivityItems} from './ScheduledActivityItems';
import {ScheduledActivity} from '../../../../syncstream/utils/RoundUtils';
import {SyringeDriverActivityDialog} from '../syringeDrivers/SyringeDriverActivityForm';
import {MedisphereSyringeDriverActivity} from '../../../../syncstream/SyncSyringeDriverActivity';
import IconDotMenu from '../../../../kit/Icons/DotMenu';
import {Popover} from 'react-tiny-popover';
import itiriri from 'itiriri';
import {checkIfMedicationIsSelfAdministered} from '../../../../syncstream/utils/PackedPatientDayUtils';
import {TestResultDetails} from '../drugDetails/TestResultsCompact';
import {Dialog} from '../../../../kit/Dialog';
import SelfAdministeredIcon from '../../../../kit/Icons/ResidentIcons/SelfAdministeredIcon';
import {EnqueuedDrugCreateData} from '../../../../syncstream/SyncRounds';
import {useGroupPermissions} from "../../../../core/authz/PermissionsProvider";
import {differenceInDays, format, startOfDay} from "date-fns";
import StopWarningIcon from "../../../../kit/Icons/StopWarning";
import {Image} from '../../../../kit/Image';

export interface ScheduledActivityInformation {
  displayAllActivity: boolean;
  selectedActivity: ScheduledActivity;
  startActivity: MedisphereSyringeDriverActivity | HSPatchObservation;
  activityRound: HSDoseRound;
  latestActivity: MedisphereSyringeDriverActivity | HSPatchObservation;
}

// TODO: PLEASE SPLIT THIS UP
export interface MedicationInformationProps extends MedicationListProps {
  infoLabel?: string;
  packedMedication?: HSPackedMedication | HSPackedPrnMedication;
  nimAvailableDrug?: NimAvailableDrugDto;
  currentDosedDrug?: HSAdministeredDrug | HSAdministeredAdHocDrug;
  previousDosedDrug?: HSAdministeredDrug | HSAdministeredAdHocDrug;
  onAddOutcome?: (outcome: DrugOutcome, administeredDrugClinicalSystemId: string) => Promise<void>;
  onAdminister?: (
    drug: HSAdministeredDrug,
    doseTimestamp: string,
    testResults?: HSTestResult[],
  ) => Promise<EnqueuedDrugCreateData | undefined>;
  onAdministerPrn?: (drug: HSAdministeredDrug) => Promise<EnqueuedDrugCreateData | undefined>;
  onAdministerAdHoc?: (drug: HSAdministeredAdHocDrug) => Promise<EnqueuedDrugCreateData | undefined>;
  displayCurrentStatusOnly?: boolean; // If this flag is set, only the current status will be displayed
  showPatientInfo?: boolean;
  isUnscheduledMedication?: boolean;
  scheduledTime?: string;
  status?: ReasonCode | AdHocReasonCode;
  orders?: ReadonlyMap<string, Order>;
  patchInfo?: PatchInfo;
  scheduledActivityInformation?: ScheduledActivityInformation;
  statusLabel?: StatusLabelInfo;
  disableAdminister?: boolean;
  groupedPackedMedicationList?: MedicationGroup[];
  isAdministerBtnDisabled?: boolean;
}

// TODO: Put this component into a folder and split the components into files
export function MedicationInformationBox(props: MedicationInformationProps) {
  const [isMedicationSelfAdministered, setIsMedicationSelfAdministered] = useState(false);
  // just to disable the dialog buttons when SA status is being updated
  const [isMedicationAPIRunning, setIsMedicationAPIRunning] = useState(false);
  const groupPermissions = useGroupPermissions();
  const [openSelfAdministerToggleDialog, setOpenSelfAdministerToggleDialog] = useState(false);
  const apiUtils = useApiUtils();
  const services = useSyncCenter();
  const user = useCurrentUser();
  const drugStore = useStore(services.drugs.store).store;
  const packedPrnMedicationStore = useStore(services.packedPatientPrnMedications.store).store;
  const drug = props.drugList?.find(
    (drug) => drug.hsId === (props.nimAvailableDrug?.drugHsId ?? props.packedMedication?.drugHsId),
  );
  const isAdHoc = !!props.nimAvailableDrug;

  if (!drug) return null;

  const chartItem = apiUtils.patients.getHSChartItemFromPackedMedication(props.patient, props.packedMedication);
  const chartItemDose = apiUtils.patients.getHSChartDoseFromChartItem(props.patient, chartItem);
  const route = props.packedMedication?.route?.code ?? undefined;
  const directions = props.packedMedication?.directions ?? undefined;
  const displaySquareBox = props.packedMedication && !props.displayCurrentStatusOnly;
  const isTimeCritical = props.packedMedication && (props.packedMedication as HSPackedMedication).timeCritical;
  const administrationWarnings = DrugUtils.getAdministrationWarnings(
    drug,
    props.packedMedication,
    apiUtils.patients.getHSMedicationFromPackedMedication(props.patient, props.packedMedication),
    props.packedMedication ? checkIfMedicationIsSelfAdministered(props.packedMedication, props.patient) : false,
  );
  const showSelfAdministerButton = DrugUtils.allowSelfAdminister(
    props.packedMedication,
    drug,
    props.isUnscheduledMedication,
  );
  const canMarkResidentMedicationAsSelfAdministered = groupPermissions.canMarkResidentMedicationAsSelfAdministered;
  const wasLate = (props.previousDosedDrug && props.previousDosedDrug.reasonCode === ReasonCode.DosedLate) ?? false;
  const hasBeenDosed = (props.previousDosedDrug && props.previousDosedDrug.reasonCode === ReasonCode.Dosed) ?? false;

  if (props.packedMedication?.medicationType === 'Prn') {
    // GroupedPackedMedicationList is an array of arrays of packed medications.  Go through every medication until we find one that matches.
      administrationWarnings.hasRelatedPrnRegular = props.groupedPackedMedicationList?.
        flatMap(medicationGroup => medicationGroup.medications).
        filter(pm => pm.medicationType !== 'Prn').
        some(pm => {
          if (pm.drugHsId === props.packedMedication?.drugHsId) {
            return true;
          }

          const regularMedicationDrug = drugStore.get(pm.drugHsId!.toString());
          const prnDrug = drugStore.get(props.packedMedication!.drugHsId!.toString());
          return regularMedicationDrug?.strength?.toLowerCase() === prnDrug!.strength?.toLowerCase() && regularMedicationDrug?.genericName?.toLowerCase() === prnDrug!.genericName?.toLowerCase();
      }) ?? false;
  }
  else {
    const values = itiriri(packedPrnMedicationStore.values());
    const packedPatientPrnMedication = values.find((prnMedication) => prnMedication.patientId === props.patient.hsId) ?? {};
    administrationWarnings.hasRelatedPrnRegular = packedPatientPrnMedication?.packedPrnMedications?.
      some((packedPrnMedication) => {
          if (packedPrnMedication.drugHsId === props.packedMedication?.drugHsId) {
              return true;
          }
          const prnMedicationDrug = drugStore.get(packedPrnMedication.drugHsId!.toString())
          const regDrug = drugStore.get(props.packedMedication?.drugHsId ? props.packedMedication.drugHsId?.toString() : '');
          if (prnMedicationDrug && regDrug) {
            return prnMedicationDrug?.strength?.toLowerCase() === regDrug!.strength?.toLowerCase() && prnMedicationDrug?.genericName?.toLowerCase() === regDrug!.genericName?.toLowerCase();
          }
      }) ?? false;
  }

  // const updateMedicationStateCallback = useCallback(
  //   (newState: boolean) => {
  //     setIsMedicationSelfAdministered(newState);
  //   },
  //   [isMedicationSelfAdministered],
  // );

  const updateMedicationAPIRunningCallback = useCallback(
    (newState: boolean) => {
      setIsMedicationAPIRunning(newState);
    },
    [isMedicationAPIRunning],
  );

  // check the SA (self-administered) state of medication when component loads
  useEffect(() => {
    const medication = props.patient.patientProfiles
      ?.flatMap((profile) => profile.allCurrentMedications)
      .find((medication) => medication?.hsId === props.packedMedication?.medicationId);

    if (medication) {
      setIsMedicationSelfAdministered(medication.selfAdministered ?? false);
    }
  }, [props.packedMedication]);

  async function triggerMedicationSelfAdministerUpdate() {
    await runMedicationStatusUpdateApiProcedure(
      !isMedicationSelfAdministered,
      // updateMedicationStateCallback,
      updateMedicationAPIRunningCallback,
      {
        ...props,
        drug: drug!,
      },
      canMarkResidentMedicationAsSelfAdministered ?? false,
      apiUtils.patients,
      apiUtils.residentDetails,
      services,
      user,
    );

    // close dialog
    setOpenSelfAdministerToggleDialog(false);
  }


  return (
    <Container
      gap={0}
      opacity={administrationWarnings.isCeasedMedication ? 0.5 : 1.0}
      data-testid="medication-information-box"
    >
      {!props.displayCurrentStatusOnly && props.infoLabel && <InfoLabel weight={'bold'}>{props.infoLabel}</InfoLabel>}
      <InformationBox gap={1} border={administrationWarnings.medicationStatus ? 'solid 4px #61E6CC' : 'none'}>
        <DetailsAndActionsContainer colsTemplate={'3fr 0fr 0.5fr 1fr'}>
          <Layout
            style={{cursor: 'pointer'}}
            onClick={() =>
              props.openDrugDetail &&
              props.openDrugDetail({
                drug: drug,
                isAdHoc: isAdHoc,
                patient: props.patient,
                packedMedication: props.packedMedication,
              })
            }
          >
            <MedicationInfoSection
              drug={drug}
              route={route}
              directions={directions}
              administrationWarnings={administrationWarnings}
              nimAvailableDrug={props.nimAvailableDrug}
              patchInfo={props.patchInfo}
              facilityGroupId={props.facilityGroupId}
              chartItemDose={chartItemDose}
              isTimeCritical={isTimeCritical}
              isPsychotropicConsent={chartItem?.psychotropicConsent}
              statusLabel={props.statusLabel}
            />
          </Layout>
          <Layout align={'center'} justify={'center'}>
            {showSelfAdministerButton && (
              <SelfAdministeredIcon cursor={'pointer'} onClick={() => setOpenSelfAdministerToggleDialog(true)}
                                    xlinkTitle={`Change Medication self-administered status?`}/>
            )}

            <Dialog
              secondary
              size={'sm'}
              open={openSelfAdministerToggleDialog}
              onRequestClose={() => setOpenSelfAdministerToggleDialog(false)}
            >
              <Layout gap={1}>
                <Text weight={'bold'} size={'medium'}>
                  Change Medication self-administered status?
                </Text>
                <Text>
                  {isMedicationSelfAdministered
                    ? 'Click yes to turn self-administered status OFF'
                    : 'Click yes to turn self-administered status ON'}
                </Text>
                <ButtonContainer>
                  <Button
                    disabled={isMedicationAPIRunning}
                    onClick={() => {
                      triggerMedicationSelfAdministerUpdate();
                    }}
                  >
                    Yes
                  </Button>
                  <Button disabled={isMedicationAPIRunning} onClick={() => setOpenSelfAdministerToggleDialog(false)}>
                    No
                  </Button>
                </ButtonContainer>
              </Layout>
            </Dialog>
          </Layout>

          {displaySquareBox ? (
            <SquareDosageBox>
              {props.currentDosedDrug ? props.currentDosedDrug.administeredDosage : props.packedMedication!.dosage}
            </SquareDosageBox>
          ) : (
            <div/>
          )}
          <RightSideInfoContainer horizontal gap={0.5}>
            <RightSideInfoSection drug={drug} {...props} />
          </RightSideInfoContainer>
        </DetailsAndActionsContainer>
      </InformationBox>
      <SecondCheckBar {...props} />
      {
        !props.displayCurrentStatusOnly && !props.showPatientInfo && props.scheduledActivityInformation && (
          <ScheduledActivityItems drug={drug} {...props} isLate={wasLate}
                                  canReadminister={props.administrationPermissions.canAdministerMissedMedication}
                                  hasBeenDosed={hasBeenDosed}/>
        )}
      {!props.displayCurrentStatusOnly && props.previousDosedDrug && (props.isUnscheduledMedication || wasLate) && (
        <PreviousDosedDrugBox drug={drug} {...props} wasLate={wasLate}/>
      )}
    </Container>
  );
}

function getPreviousAdministeredDrugOutcome(
  administeredDrug: HSAdministeredDrug | HSAdministeredAdHocDrug | undefined,
) {
  if (administeredDrug === undefined) {
    return undefined;
  }
  if ('administeredDrugOutcomes' in administeredDrug) {
    return administeredDrug.administeredDrugOutcomes?.find((outcome) => outcome.active);
  } else if ('administeredAdHocDrugOutcomes' in administeredDrug) {
    return administeredDrug.administeredAdHocDrugOutcomes?.find((outcome) => outcome.active);
  }
}

function RightSideInfoSection(props: MedicationAdministerProps) {
  const apiUtils = useApiUtils();
  if (props.showPatientInfo) {
    return (
      <ResidentInfo cols={3} alignItemsCenter>
        <ResidentThumbnail src={props.patient.imageUrl!} facilityGroupId={props.facilityGroupId}/>
        <div>
          <span style={{fontWeight: 'bold'}}>{apiUtils.patients.getDisplayPatientName(props.patient)}</span>
        </div>
      </ResidentInfo>
    );
  }

  return !props.displayCurrentStatusOnly ? (
    <MedicationActions {...props} />
  ) : (
    <>
      {props.previousDosedDrug?.reasonCode && (
        <ActionsLayout>
          {apiUtils.residentDetails.reasonCodeToString(props.previousDosedDrug?.reasonCode)}
        </ActionsLayout>
      )}{' '}
    </>
  );
}

function isLate(roundWindow: Interval, props: MedicationAdministerProps): boolean {
  if (isPrn(props)) {
    // PRN's can never be late.
    return false;
  }

  return !!roundWindow && !!props.scheduledTime && DateUtils.compareDates(DateUtils.toOffsetlessDate(roundWindow.start), DateUtils.toOffsetlessDate(props.scheduledTime)) < 0;
}

function isPrn(props: MedicationAdministerProps): boolean {
  return props.packedMedication?.medicationType === MedicationType.Prn ?? false;
}

function inRound(props: MedicationAdministerProps): boolean {
  return props.roundSchedule?.some(
    (item) =>
      item.patient.hsId === props.patient.hsId && item.packedMedication.hsId === props.packedMedication?.hsId,
  ) ?? false;
}

function canAdminister(props: MedicationAdministerProps): boolean {

  if (props.isUnscheduledMedication) {
    return props.administrationPermissions.canAdministerPRNAndRecordItsOutcome;
  }
  // If scheduled, check if in round
  if (!inRound(props)) {
    return false;
  }


  // If administering a patch you must be in a round
  if (PatchUtils.isPatch(props.drug) && !props.currentRound) {
    return false;
  }
  return true;
}

function canBeReAdministered(interval: Interval, props: MedicationAdministerProps): boolean {
  const today = startOfDay(new Date());
  if (isPrn(props) || inRound(props) || ((today.getTime() !== startOfDay(props.selectedDate).getTime()) ?? false)) {
    // Ignore if it is a prn, or if it is in a round, or it is not today's dose.
    return false;
  }
  if (props.administrationPermissions.canAdministerMissedMedication && !props.status && isLate(interval, props)) {
    // This med has not been administered, and it is late and we are allowed to administer late meds.
    return true;
  }

  // If t has already been administered and we are allowed to re-administer then we can re-administer.
  return (props.administrationPermissions.canReAdministerMedication && !!props.status);
}

// eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
function MedicationActions(props: MedicationAdministerProps) {
  const [open, setOpen] = useState(false);
  const apiUtils = useApiUtils();
  const interval = apiUtils.rounds.getRoundWindow(new Date(), props.facilityGroupId);
  const administrationWarnings = DrugUtils.getAdministrationWarnings(
    props.drug,
    props.packedMedication,
    apiUtils.patients.getHSMedicationFromPackedMedication(props.patient, props.packedMedication),
  );
  const secondCheckInfo = props.secondCheckData?.secondCheckableMedication.find((med) =>
    props.nimAvailableDrug ? med.id === props.nimAvailableDrug.id : med.id === props.packedMedication?.hsId,
  );
  const isPatch = PatchUtils.isPatch(props.drug);
  const hsMedication = props.patient.patientProfiles
    ?.flatMap((profile) => profile.allCurrentMedications)
    .find((medication) => medication?.hsId === props.packedMedication?.medicationId);
  const administerText =
    administrationWarnings.inrTestRequired || administrationWarnings.bglTestRequired ? 'RUN TEST' : hsMedication?.selfAdministered ? 'SELF ADMINISTER' : 'ADMINISTER';
  const requiresSecondCheck = apiUtils.residentDetails.isMedicationSecondCheckable(props.facilityGroupId, props.administrationPermissions.canAdministerPatchMedicationExcludingS8 ?? false, props.packedMedication ?? props.nimAvailableDrug!) && !(secondCheckInfo?.userName);
  const isAdministerable = canAdminister(props);
  // Means that the user has rights to re-adminster, and the dose is one that can be re-administered.
  const isReAdministrable = canBeReAdministered(interval, props);
  const hasBeenDosed = !!props.status && props.status === ReasonCode.Dosed;
  // Means that this is the dose can be re-administered, and it is late.
  const isLateDose = isReAdministrable && isLate(interval, props);
  const user = useCurrentUser();
  const isSyringeDriver = props.packedMedication?.route?.code === 'SID';
  const packed = (props.packedMedication?.packType === PackType.Packette || props.packedMedication?.packType === PackType.Blister) ?? false;

  const administrationDialogProps = {
    ...props,
    administrable: isAdministerable || isReAdministrable,
    open: open,
    setOpen: (setToOpen: boolean) => {
      const administrationError = getAdministrationError(props);
      if (administrationError) {
        toasts.error(administrationError);
        return;
      }
      setOpen(setToOpen);
    },
    onAdminister: async (drug: HSAdministeredDrug, doseTimestamp: string, testResults?: HSTestResult[]) => {
      const newDrug = await props.onAdminister?.(drug, doseTimestamp, testResults);
      setOpen(false);
      return newDrug;
    },
    onAdministerAdHoc: async (drug: HSAdministeredAdHocDrug) => {
      const newDrug = await props.onAdministerAdHoc?.(drug);
      setOpen(false);
      return newDrug;
    },
    confirmationInitials: secondCheckInfo?.userName,
    confirmationUserId: secondCheckInfo?.hsId,
    requiresSecondCheck: requiresSecondCheck,
  };

  // Display if syringe driver has already been administered
  if (props.scheduledActivityInformation && isSyringeDriverActivity(props.scheduledActivityInformation.startActivity)) {
    return <SyringeDriverActions {...administrationDialogProps} requiresSecondCheck={requiresSecondCheck}/>;
  }

  // Display if syringe driver has already been administered
  if (
    props.scheduledActivityInformation &&
    !isSyringeDriverActivity(props.scheduledActivityInformation.startActivity)
  ) {
    return <PatchActions {...administrationDialogProps} requiresSecondCheck={requiresSecondCheck}/>;
  }

  if (isPatch) {
    return (
      <ActionsLayout horizontal gap={0.5}>
        <PatchMedicationActions
          {...props} isLate={isLateDose}
          requiresSecondCheck={requiresSecondCheck}
          administerable={isAdministerable}
          isReAdministrable={isReAdministrable}
          confirmationInitials={secondCheckInfo?.userName}
          confirmationUserId={secondCheckInfo?.hsId}
        />
      </ActionsLayout>
    );
  }

  const getAdministerBtnDisabilityStatus = (): boolean => {
    const reasonCode = apiUtils.residentDetails.reasonCodeToString(props.previousDosedDrug?.reasonCode);

    return props.disableAdminister || props.isAdministerBtnDisabled
            || !props.administrationPermissions.canAdministerNonPackedMedicationExcludingInjectable
            && props.administrationPermissions.canAdministerPackedMedicationIndividually;
  };


  return (
    <ActionsLayout horizontal gap={0.5}>
      {isAdministerable && !props.status ? (
        requiresSecondCheck && (!packed || !props.administrationPermissions.packedMedsDoNotNeedSecondCheck) && props.administrationPermissions.canSetOtherDoseStatuses ? (
          <SecondCheckDialogContainer {...props} />
        ) : (
          <>
            <ActionButton
              data-testid="administer-button"
              onClick={async () => {
                const administrationError = getAdministrationError(props);
                if (administrationError) {
                  toasts.error(administrationError);
                  return;
                }
                props.isUnscheduledMedication ||
                isSyringeDriver ||
                administrationWarnings.inrTestRequired ||
                administrationWarnings.bglTestRequired
                  ? setOpen(true)
                  : await quickAdministerDrug(props, apiUtils.residentDetails, user, secondCheckInfo);
              }}
              disabled={getAdministerBtnDisabilityStatus()}
            >
              {administerText}
            </ActionButton>
          </>
        )
      ) : (
        <MedicationStatus {...props} status={props.status} />
      )
      }
      {((isAdministerable || isReAdministrable) && props.administrationPermissions.canSetOtherDoseStatuses) && (
        <>
          {' '}
          {isSyringeDriver ? (
            <>
              <SyringeDriverAdministerDialog {...administrationDialogProps} isLate={isLateDose ?? false}
                                             hasBeenDosed={hasBeenDosed ?? false}/>
              <IconDotMenu
                style={{cursor: 'pointer'}}
                onClick={() => {
                  const administrationError = getAdministrationError(props);
                  if (administrationError) {
                    toasts.error(administrationError);
                    return;
                  }
                  setOpen(true);
                }}
              />
            </>
          ) : (
            <MedicineAdministerDialog {...administrationDialogProps} isLate={isLateDose} hasBeenDosed={hasBeenDosed}
                                      isDisabled={!props.administrationPermissions.canWithholdMedication || props.isAdministerBtnDisabled}/>
          )}
        </>
      )}
    </ActionsLayout>
  );
}

interface SyringeDriverProps extends MedicationAdministerProps {
  requiresSecondCheck?: boolean;
  confirmationInitials?: string;
  confirmationUserId?: number;
  administrable?: boolean;
}

// eslint-disable-next-line max-lines-per-function
function SyringeDriverActions(props: SyringeDriverProps) {
  const [administrationDataOpen, setAdministrationDataOpen] = useState(false);
  const [activityKind, setActivityKind] = useState<SyringeDriverActivityKind>();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const services = useSyncCenter();
  const syringeDriverActivityStore = useStore(services.syringeDriverActivity.store).store;
  const administeredDriverActivity = itiriri(syringeDriverActivityStore.values())
    .filter(
      (activity) =>
        !!props.scheduledActivityInformation &&
        isSyringeDriverActivity(props.scheduledActivityInformation.startActivity) &&
        ((activity.administeredDrugClinicalSystemId ?? false) ===
          props.scheduledActivityInformation?.startActivity?.administeredDrugClinicalSystemId ||
          (activity.administeredDrugId ?? false) ===
          props.scheduledActivityInformation?.startActivity?.administeredDrugId),
    )
    .toArray();
  const latestStartOrStopOrPause = administeredDriverActivity
    .sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt))
    .find(
      (activity) =>
        !!(
          activity.kind &&
          [
            SyringeDriverActivityKind.Start.valueOf(),
            SyringeDriverActivityKind.Stop.valueOf(),
            SyringeDriverActivityKind.Cease.valueOf(),
            SyringeDriverActivityKind.Restart.valueOf(),
            SyringeDriverActivityKind.Pause.valueOf(),
          ].includes(activity.kind)
        ),
    );
  const latestKind = latestStartOrStopOrPause?.kind ?? SyringeDriverActivityKind.Start;

  function SyringeButton() {
    switch (latestKind) {
      case SyringeDriverActivityKind.Stop:
        return <Text>STOPPED</Text>;
      case SyringeDriverActivityKind.Cease:
        return <Text>CEASED</Text>;
      case SyringeDriverActivityKind.Pause:
        return props.requiresSecondCheck ? (
          <SecondCheckDialogContainer {...props} isLate={props.isLate}/>
        ) : (
          <ActionButton onClick={() => openActivityDialog(SyringeDriverActivityKind.Restart)}>RESTART</ActionButton>
        );
      case SyringeDriverActivityKind.Start:
      case SyringeDriverActivityKind.Restart:
        return props.status === ReasonCode.DosedLate ? <Text>ADMINISTERED LATE</Text> : <Text>ADMINISTERED</Text>;
      default:
        return null;
    }
  }

  function openActivityDialog(kind?: SyringeDriverActivityKind) {
    if (!props.administrationPermissions.canOperateSyringeDriver) {
      toasts.error('You do not have permission to operate syringe driver');
      return;
    }
    setActivityKind(kind);
  }

  return (
    <ActionsLayout horizontal gap={0.5}>
      <SyringeButton/>
      <Popover
        isOpen={popoverOpen}
        onClickOutside={() => setPopoverOpen(false)}
        positions={['right', 'bottom', 'top', 'left']}
        containerStyle={{overflow: 'hidden', zIndex: '99999'}}
        content={
          <OptionBoxContainer>
            <OptionContainer
              onClick={() => {
                openActivityDialog(SyringeDriverActivityKind.Stop);
                setPopoverOpen(false);
              }}
            >
              <Text>STOP</Text>
            </OptionContainer>
            <OptionContainer
              onClick={() => {
                setAdministrationDataOpen(true);
                setPopoverOpen(false);
              }}
            >
              <Text>VIEW</Text>
            </OptionContainer>
          </OptionBoxContainer>
        }
      >
        <div>
          <IconDotMenu
            style={{cursor: 'pointer'}}
            onClick={() => {
              latestKind === SyringeDriverActivityKind.Stop
                ? setPopoverOpen(true)
                : latestKind === SyringeDriverActivityKind.Restart || latestKind === SyringeDriverActivityKind.Start
                  ? openActivityDialog(SyringeDriverActivityKind.Stop)
                  : setAdministrationDataOpen(true);
            }}
          />
        </div>
      </Popover>
      <SyringeDriverAdministerDialog {...props} open={administrationDataOpen} setOpen={setAdministrationDataOpen}
                                     isLate={props.isLate ?? false} hasBeenDosed={props.hasBeenDosed ?? false}/>
      {activityKind && (
        <SyringeDriverActivityDialog
          {...props}
          open={!!activityKind}
          setOpen={(bool) => openActivityDialog(bool ? activityKind : undefined)}
          activityKind={activityKind}
        />
      )}
    </ActionsLayout>
  );
}

// eslint-disable-next-line max-lines-per-function
function PatchActions(props: SyringeDriverProps) {
  const [administrationDataOpen, setAdministrationDataOpen] = useState(false);
  const [activityKind, setActivityKind] = useState<PatchStatus>();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const services = useSyncCenter();
  const packedPatientDaysStore = useStore(services.packedPatientDays.store).store;
  const patchObservationStore = useStore(services.patchObservations.store).store;
  const patchObservations = itiriri(patchObservationStore.values())
    .filter(
      (activity) =>
        !!props.scheduledActivityInformation &&
        !isSyringeDriverActivity(props.scheduledActivityInformation.startActivity) &&
        PatchUtils.getPatchOperationData(activity)?.packedMedicationId ===
        PatchUtils.getPatchOperationData(props.scheduledActivityInformation.startActivity)?.packedMedicationId,
    )
    .toArray();
  const latestAppliedOrRemovedOrFallenOff = patchObservations
    .sort((a, b) => DateUtils.compareDateStringsDescending(a.createdAt, b.createdAt))
    .find((activity) => {
      const patchStatus = PatchUtils.getPatchOperationData(activity)?.patchStatus;
      return !!(
        patchStatus &&
        [PatchStatus.Applied, PatchStatus.Reapplied, PatchStatus.FallenOff, PatchStatus.Removed].includes(patchStatus)
      );
    });

  const latestKind = latestAppliedOrRemovedOrFallenOff
    ? PatchUtils.getPatchOperationData(latestAppliedOrRemovedOrFallenOff)?.patchStatus ?? PatchStatus.Applied
    : PatchStatus.Applied;

  const nextPatches = itiriri(packedPatientDaysStore.values())
    .flat((day) => day.packedMedications ?? [])
    .filter(
      (med) =>
        med.medicationId === props.packedMedication?.medicationId &&
        med.drugHsId === props.packedMedication?.drugHsId &&
        !!med.doseTimestamp &&
        !!props.scheduledTime &&
        DateUtils.toDate(med.doseTimestamp).valueOf() > DateUtils.toDate(props.scheduledTime).valueOf(),
    );

  const upcomingPatchHasActivity = itiriri(patchObservationStore.values()).some((activity) =>
    nextPatches.some((patch) => PatchUtils.getPatchOperationData(activity)?.packedMedicationId === patch.hsId),
  );

  function PatchButton() {
    switch (latestKind) {
      case PatchStatus.Removed:
        return <Text>REMOVED</Text>;
      case PatchStatus.FallenOff:
        return !props.scheduledActivityInformation?.startActivity.createdAt ||
        differenceInDays(
          DateUtils.toDate(props.scheduledActivityInformation?.startActivity.createdAt),
          new Date(),
        ) <= 7 ? (
          props.requiresSecondCheck ? (
            <SecondCheckDialogContainer {...props} isLate={props.isLate}/>
          ) : (
            <ActionButton
              onClick={() =>
                upcomingPatchHasActivity
                  ? toasts.error("Can't reapply patch. Another instance of this patch has already been administered")
                  : setActivityKind(PatchStatus.Reapplied)
              }
            >
              REAPPLY
            </ActionButton>
          )
        ) : (
          <Text>FALLEN OFF</Text>
        );
      case PatchStatus.Applied:
      case PatchStatus.Reapplied:
        return props.status === ReasonCode.DosedLate ? <Text>ADMINISTERED LATE</Text> : <Text>ADMINISTERED</Text>;

      default:
        return null;
    }
  }


  return (
    <ActionsLayout horizontal gap={0.5}>
      <PatchButton/>
      {(props.administrable || props.administrationPermissions.canAdministerPatchMedicationExcludingS8) &&
          <Popover
              isOpen={popoverOpen}
              onClickOutside={() => setPopoverOpen(false)}
              positions={['right', 'bottom', 'top', 'left']}
              containerStyle={{overflow: 'hidden', zIndex: '99999'}}
              content={
                <OptionBoxContainer>
                  <OptionContainer
                    onClick={() => {
                      setActivityKind(PatchStatus.Removed);
                      setPopoverOpen(false);
                    }}
                  >
                    <Text>STOP</Text>
                  </OptionContainer>
                  <OptionContainer
                    onClick={() => {
                      setAdministrationDataOpen(true);
                      setPopoverOpen(false);
                    }}
                  >
                    <Text>VIEW</Text>
                  </OptionContainer>
                </OptionBoxContainer>
              }>
              <DotsButton onClick={() => {
                latestKind === PatchStatus.FallenOff
                  ? setPopoverOpen(true)
                  : latestKind === PatchStatus.Reapplied || latestKind === PatchStatus.Applied
                    ? setActivityKind(PatchStatus.Removed)
                    : setAdministrationDataOpen(true);
              }}>
                  <IconDotMenu/>
              </DotsButton>
          </Popover>
      }
      <PatchAdministerDialog
        {...props}
        open={administrationDataOpen}
        setOpen={setAdministrationDataOpen}
        hideButton
        viewOnly
      />
      {activityKind && (
        <PatchAdministerDialog
          {...props}
          open={!!activityKind}
          setOpen={(bool) => setActivityKind(bool ? activityKind : undefined)}
          patchStatus={activityKind}
          isSighting={activityKind === PatchStatus.Sighted}
          isReapplyPatch={activityKind === PatchStatus.Reapplied}
          isUpdatePatch={activityKind !== PatchStatus.Reapplied}
          hideButton
        />
      )}
    </ActionsLayout>
  );
}

interface IPatchMedicationActionsProps extends MedicationAdministerProps {
  requiresSecondCheck?: boolean;
  administerable: boolean;
  isReAdministrable: boolean;
  isLate: boolean;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function PatchMedicationActions(props: IPatchMedicationActionsProps) {
  const {requiresSecondCheck, administerable, patchInfo, status, isReAdministrable} = props;
  const patchStatus = patchInfo?.PatchStatus;
  const [open, setOpen] = useState(false);

  // TODO: Add comments to describe this
  const isPatchRemoval = PatchUtils.isPatchRemoval(props.drug);
  const canDoPatchSpecificUpdate = status === ReasonCode.Dosed && !props.currentRound && !isPatchRemoval;
  const showMedicationActionButton =
    (administerable && !status) ||
    patchStatus === PatchStatus.ToSight ||
    (canDoPatchSpecificUpdate && requiresSecondCheck);
  const showMedicationStatus = (!administerable || status) && patchStatus !== PatchStatus.ToSight;

  return (
    <>
      {showMedicationActionButton &&
        (requiresSecondCheck ? (
          <SecondCheckDialogContainer {...props} isLate={props.isLate}/>
        ) : (
          <PatchAdministerDialog {...props} isSighting={patchStatus === PatchStatus.ToSight}/>
        ))}
      {showMedicationStatus && <MedicationStatus {...props} patchStatus={patchStatus} isLate={props.isLate}/>}
      {(isReAdministrable || administerable) && !isPatchRemoval && (
        <MedicineAdministerDialog
          {...props}
          open={open}
          setOpen={(setDialogOpen: boolean) => {
            const administrationError = getAdministrationError(props);
            if (administrationError) {
              toasts.error(administrationError);
              return;
            }
            setOpen(setDialogOpen);
          }}
          // eslint-disable-next-line sonarjs/no-identical-functions
          onAdminister={async (drug: HSAdministeredDrug, doseTimestamp: string) => {
            const newDrug = await props.onAdminister?.(drug, doseTimestamp);
            setOpen(false);
            return newDrug;
          }}
        />
      )}
      {canDoPatchSpecificUpdate && !requiresSecondCheck && (
        <PatchAdministerDialog
          {...props}
          isUpdatePatch={patchStatus !== PatchStatus.FallenOff}
          isReapplyPatch={patchStatus === PatchStatus.FallenOff}
          patchStatus={patchStatus}
        />
      )}
    </>
  );
}

export function SecondCheckDialogContainer(props: MedicationAdministerProps) {
  return (
    <SecondCheckDialog
      {...props}
      onSubmit={(secondCheckUser) => {
        props.secondCheckData?.setMedicationSecondChecked({
          id: props.packedMedication?.hsId ?? props.nimAvailableDrug!.id!,
          userName: secondCheckUser.email!,
          hsId: secondCheckUser.hsId,
          date: new Date(),
        });
      }}
    />
  );
}

function SecondCheckBar(props: MedicationInformationProps) {
  const apiUtils = useApiUtils();

  const secondCheckInfo = props.secondCheckData?.secondCheckableMedication.find((med) =>
    props.nimAvailableDrug ? med.id === props.nimAvailableDrug.id : med.id === props.packedMedication?.hsId,
  );

  if (secondCheckInfo?.userName) {
    return (
      <SecondCheckDetailsBar
        userName={
          apiUtils.users.getUserFullNameAndRoleFromUserOrEmailId(secondCheckInfo.userName, secondCheckInfo.hsId) ?? secondCheckInfo.userName
        }
        date={secondCheckInfo.date}
      />
    );
  }

  const confirmedDrug = props.isUnscheduledMedication
    ? props.previousDosedDrug
    : apiUtils.rounds
      .getAdministeredDrugsForPackedMedication(props.packedMedication!, props.patient.hsId!)
      .find((drug) => !!drug.confirmationInitials);

  if (confirmedDrug?.confirmationInitials) {
    return (
      <SecondCheckDetailsBar
        userName={
          apiUtils.users.getUserFullNameAndRoleFromUserOrEmailId(confirmedDrug.confirmationInitials, confirmedDrug.confirmationUserId) ??
          confirmedDrug.confirmationInitials
        }
        date={confirmedDrug.administeredAt ? DateUtils.toDate(confirmedDrug.administeredAt) : undefined}
      />
    );
  }
  return <></>;
}

async function quickAdministerDrug(
  props: MedicationAdministerProps,
  residentDetailsUtils: ResidentDetailsUtils,
  user: User,
  secondCheckInfo?: SecondCheckableMedication,
) {
  if (props.packedMedication && 'doseTimestamp' in props.packedMedication && props.packedMedication.doseTimestamp) {
    if (!props.onAdminister) {
      throw new Error('Missing onAdminister prop');
    }

    const hsMedication = props.patient.patientProfiles
      ?.flatMap((profile) => profile.allCurrentMedications)
      .find((medication) => medication?.hsId === props.packedMedication?.medicationId);

    await props.onAdminister(
      residentDetailsUtils.generateAdministeredDrug(
        props.packedMedication!,
        props.drug,
        hsMedication?.selfAdministered ? ReasonCode.SelfAdministered : ReasonCode.Dosed,
        [],
        user,
        secondCheckInfo?.userName,
        secondCheckInfo?.hsId,
      ),
      props.packedMedication.doseTimestamp,
    );
  } else {
    if (!props.onAdministerPrn) {
      throw new Error('Missing onAdminister prop');
    }

    await props.onAdministerPrn(
      residentDetailsUtils.generateAdministeredDrug(
        props.packedMedication!,
        props.drug,
        ReasonCode.Dosed,
        [],
        user,
        secondCheckInfo?.userName,
        secondCheckInfo?.hsId,
      ),
    );
  }
}

interface IMedicationStatusProps extends MedicationAdministerProps {
  patchStatus?: PatchStatus;
}

function MedicationStatus(props: IMedicationStatusProps) {
  const apiUtils = useApiUtils();

  const roundWindow = props.currentRound?.createdAt
    ? apiUtils.rounds.getRoundWindow(DateUtils.toDate(props.currentRound.createdAt), props.facilityGroupId)
    : apiUtils.rounds.getRoundWindow(new Date(), props.facilityGroupId);

  const getMedicationStatus = (): string => {
    if (props.patchStatus) {
      return getPatchStatusText(props.patchStatus);
    }
    if (props.status) {
      return apiUtils.residentDetails.reasonCodeToString(props.status);
    }
    if ((props.nimAvailableDrug || isPrn(props)) && props.previousDosedDrug) {
      return apiUtils.residentDetails.reasonCodeToString(props.previousDosedDrug.reasonCode);
    } else {
      return props.isUnscheduledMedication || (props.scheduledTime !== undefined && // If the scheduleTime exists and scheduledTime is after now
        props.scheduledTime >= roundWindow.start)
        ? 'Not Administered'
        : 'Missed';
    }
  };

  return <Text>{getMedicationStatus().toUpperCase()}</Text>;
}

const ButtonContainer = styled.div`
  display: flex;
  gap: 0.5em;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

interface PreviousDosedDrugBoxProps extends MedicationAdministerProps {
  wasLate: boolean;
}

function PreviousDosedDrugBox(props: PreviousDosedDrugBoxProps) {
  const [open, setOpen] = useState(false);
  const previousOutcome = getPreviousAdministeredDrugOutcome(props.previousDosedDrug);
  const apiUtils = useApiUtils();
  const testResults = apiUtils.residentDetails.getTestResultsForAdministeredDrug(props.previousDosedDrug!);

  return (
    <LastDoseBox>
      <Text weight={'bold'}>LAST DOSE {
        props.wasLate && (<AdministeredLateContainer>ADMINISTERED LATE</AdministeredLateContainer>)
      }
      </Text>
      <br/>
      <DetailsAndActionsContainer cols={3} colsTemplate={'3fr 1fr 1fr'} style={{marginTop: "1em"}}>
        <Layout>
          <Text weight="bold">
            {props.previousDosedDrug?.administeredDosage}X {props.drug.strength}
          </Text>
          <Text>
            {props.previousDosedDrug?.administeredAt &&
              format(DateUtils.toDate(props.previousDosedDrug.administeredAt), 'PPP HH:mm')}
            &nbsp;by{' '}
            {apiUtils.users.getUserFullNameAndRoleFromSubjectId(props.previousDosedDrug?.administeredBySubjectId)}
          </Text>
        </Layout>
        <Layout>
          {props.onAddOutcome && (
            <ActionsLayout horizontal gap={0.5}>
              {previousOutcome ? (
                <OutcomeBox gap={0.5}>
                  <Text>{previousOutcome.commentText}</Text>
                </OutcomeBox>
              ) : (
                <>
                  <ActionButton
                    onClick={() => {
                      const administrationError = getAdministrationError(props);
                      if (administrationError) {
                        toasts.error(administrationError);
                        return;
                      }
                      setOpen(true);
                    }}
                    style={{marginRight: '1.5rem'}}
                  >
                    OUTCOME
                  </ActionButton>
                </>
              )}
              <DrugOutcomeDialog
                patient={props.patient}
                drug={props.drug}
                packedMedication={props.packedMedication}
                administeredDrugClinicalSystemId={props.previousDosedDrug!.clinicalSystemId!}
                isVisible={open}
                onClose={() => setOpen(false)}
                onAddOutcome={async (outcome, administeredDrugClinicalSystemId) => {
                  await props.onAddOutcome!(outcome, administeredDrugClinicalSystemId);
                  setOpen(false);
                }}
                isAdHoc={!!props.nimAvailableDrug}
              />
            </ActionsLayout>
          )}
        </Layout>
        <Layout>
          {
            props.wasLate && (<StopWarningIcon height="3em" width="3em"/>)
          }
        </Layout>

      </DetailsAndActionsContainer>
      {testResults.length > 0 && <Text weight={'bold'}>TEST RESULTS</Text>}
      {testResults.map((test) => (
        <TestResultDetails key={test.clinicalSystemId} testResult={test}/>
      ))}
    </LastDoseBox>
  );
}

const AdministeredLateContainer = styled.span`
  margin-left: 2em;
  color: red;
`;

// eslint-disable-next-line sonarjs/cognitive-complexity
export function getAdministrationError(props: PatchAdministerProps) {
  // Check for administration permission
  if (!props.administrationPermissions.canAdministerMedication) {
    return 'You do not have permission to administer medication.';
  }
  if (!props.administrationPermissions.canAdministerMissedMedication && !!props.isLate) {
    return 'You do not have permission to administer missed medication.';
  }

  // TODO:  REFACTOR THIS TO USE THE canAdminister method.
  // If it's a controlled drug, check for controlled drug administration permission
  // Is packed?
  const {
    packed,
    interim,
    syringeDriver,
    injectable,
    controlled,
    schedule4,
    shortCourse,
    insulin,
    warfarin,
    prn
  } = DrugUtils.getMedicationInformation(props.packedMedication, props.drug);


  if (warfarin && !props.administrationPermissions?.canAdministerSlidingScaleMedication) {
    return "You do not have permission to administer sliding scale medication";
  }

  // NB:  props.PatchInfo refers to the original patch - not this activity.
  // So the check here, is, if the original patch was applied, and it is a sighting, and we can sight patches, then we are
  // good to go.
  if (props.patchInfo && props.isSighting && props.administrationPermissions.canSightPatch) {
    // Shortcut - if you can sight a patch, you can sight any sort of patch even if it is an s8 or an s4.
    return undefined;
  }

  if (controlled && !(
    // Can administer controlled drugs
    props.administrationPermissions.canAdministerControlledDrugs ||
    // It's packed and we can administer packed controlled drugs
    ((props.packedMedication?.packType ?? PackType.OriginalContainer) !== PackType.OriginalContainer &&
      props.administrationPermissions.canAdministerPackedControlledDrugs))) {
    return 'You do not have permission to administer controlled drugs.';
  }
  // If it's a controlled drug, check for S8 administration permission
  // TODO:  Remove either this or the canAdministerControlledDrugs check.
  if (controlled && !(
    // Can administer controlled drugs
    props.administrationPermissions.canAdministerS8Medication ||
    // It's packed and we can administer packed controlled drugs
    ((packed || interim) && props.administrationPermissions.canAdministerPackedControlledDrugs))
  ) {
    return 'You do not have permission to administer schedule 8 medication.';
  }
  // If it's a schedule 4 drug, check for S4 administration permission
  if (schedule4 && !props.administrationPermissions.canAdministerS4Medication) {
    return 'You do not have permission to administer schedule 4 medication.';
  }
  // If it's a short course medication, check for short course permission
  if (shortCourse && !props.administrationPermissions.canAdministerShortCourseMedication) {
    return 'You do not have permission to administer short course medication.';
  }
  // If it's a PRN medication, check for PRN administration permission
  if (prn && !props.administrationPermissions.canAdministerPRNAndRecordItsOutcome
  ) {
    return 'You do not have permission to administer PRN medication.';
  }
  // If it's a NIM medication, check for nim administration permission
  if (props.nimAvailableDrug && !props.administrationPermissions.canAdministerNIMAndRecordItsOutcome) {
    return 'You do not have permission to administer NIM medication.';
  }
  // If it's not packed medication, check for non-packed medication administration permission
  if (!packed &&
    !(
      props.administrationPermissions.canAdministerNonPackedMedicationExcludingInjectable ||
      props.administrationPermissions.canAdministerNonPackedMedicationIncludingInjectable
    )
  ) {
    return 'You do not have permission to administer non-packed medication.';
  }
  // If it's a non-packed injection, check for non-packed injection administration permission
  if (!packed && injectable && !props.administrationPermissions.canAdministerNonPackedMedicationIncludingInjectable) {
    return 'You do not have permission to administer non-packed injections.';
  }
  // If it's a packed medication, check for packed medication administration permission
  if (packed && !props.administrationPermissions.canAdministerPackedMedication) {
    return 'You do not have permission to administer Packed Medication.';
  }
  // if it's a patch, check for patch administration permission
  if (props.patchInfo &&
    !(
      props.administrationPermissions.canAdministerPatchMedicationExcludingS8 ||
      props.administrationPermissions.canAdministerPatchMedicationIncludingS8
    ) && !props.administrationPermissions.canSightPatch
  ) {
    return 'You do not have permission to administer Patches.';
  }
  // if it's a S8 patch, check for S8 patch administration permission
  if (props.patchInfo && controlled && !props.administrationPermissions.canAdministerPatchMedicationIncludingS8) {
    return 'You do not have permission to administer S8 Patches.';
  }
  // if it's a syringe driver, check for syringe driver administration permission
  if (syringeDriver && !props.administrationPermissions.canOperateSyringeDriver) {
    return 'You do not have permission to administer syringe drivers.';
  }
  return undefined;
}

const Container = styled(Layout)`
  align-items: flex-start;
  flex-direction: column;
  display: flex;
`;

const InfoLabel = styled(Text)`
  margin-left: .25em;
  line-height: 1.25;
  font-weight: bold;
  background: rgba(225, 175, 255, 1);
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
`;

const InformationBox = styled(Layout)`
  background: white;
  color: ${(props) => props.theme.button.info.fg};
  align-items: center;
  padding: 0.875rem;
  width: 100%;
  min-height: 112px;
  border-radius: 2px;
`;

const DetailsAndActionsContainer = styled(Grid)`
  width: 100%;
`;

const LastDoseBox = styled.div`
  background-color: rgba(246, 233, 254, 1);
  padding: 1.25rem;
  flex-direction: column;
  width: 100%;
`;

export const SquareDosageBox = styled.div`
  justify-self: flex-end;
  align-self: center;
  line-height: 1.25;
  text-align: center;
  padding: 0.625rem;
  border: 3px solid rgb(0, 0, 0);
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
`;

const RightSideInfoContainer = styled(Layout)`
  display: flex;
  align-items: center;
  justify-self: center;
`;

const ActionsLayout = styled(Layout)`
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const OutcomeBox = styled(Layout)`
  padding: 0.75rem;
  border-color: black;
  border-opacity: 0.4;
  border-style: solid;
`;

const ActionButton = styled(Button)`
  min-width: 10rem;
  &:disabled {
    cursor: not-allowed;
  }
`;

const DotsButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  &:disabled {
    cursor: not-allowed;
  }
`;

const ResidentInfo = styled(Grid)`
  margin-left: auto;
`;

const ResidentThumbnail = styled(Image)`
  width: 40px;
  border-radius: 50%;
`;

const OptionContainer = styled.div`
  padding-left: 0.75rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-right: 0.75rem;
  &:hover {
    background-color: ${(props) => props.theme.dropdown.default.bg};
    color: ${(props) => props.theme.dropdown.default.fg};
  }
`;

const OptionBoxContainer = styled.div`
  color: ${(props) => props.theme.dropdown.light.fg};
  background-color: ${(props) => props.theme.dropdown.light.bg};
`;
